import React, { Fragment, useRef, useEffect, useState, PureComponent, forceUpdate } from "react";
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import './App.css';
import { doc, getDoc, setDoc, deleteDoc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useMediaQuery } from '@mui/material';

import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';

import CircularProgress from '@mui/material/CircularProgress';


import PlusIcon from '@mui/icons-material/Add';
import SideBarIcon from '@mui/icons-material/ArrowForwardIos';
import CloseArrowIcon from '@mui/icons-material/ArrowBackIos';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CheckIcon from "@mui/icons-material/Tag"
import SuccessIcon from "@mui/icons-material/Check"
import TextIcon from "@mui/icons-material/TextSnippet"


import ReaderIcon from '@mui/icons-material/MenuBook';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import ScanIcon from '@mui/icons-material/DocumentScannerRounded'
import PDFIcon from '@mui/icons-material/PictureAsPdf'
import CheckMarkIcon from '@mui/icons-material/CheckCircle'
import StarsIcon from '@mui/icons-material/AutoAwesome'
import PublishIcon from '@mui/icons-material/Publish';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import Filter1Icon from '@mui/icons-material/Filter1';
import IconButton from '@mui/material/IconButton';
import AutoFixIcon from '@mui/icons-material/AutoFixHigh';
import DifferenceIcon from '@mui/icons-material/Difference';
import PreviewIcon from '@mui/icons-material/Visibility';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import FlagIcon from '@mui/icons-material/Flag';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TitleIcon from '@mui/icons-material/Title';
import Tooltip from '@mui/material/Tooltip';
import Drawer from '@mui/material/Drawer';
import DialogTitle from '@mui/material/DialogTitle';
import Check from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AssistButton from "./AssistButton/AssistButton";
import EditIcon from '@mui/icons-material/Edit';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import CenterIcon from '@mui/icons-material/FormatAlignCenter';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TocIcon from '@mui/icons-material/Toc';
import DoneIcon from '@mui/icons-material/Done';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import FormControl from '@mui/material/FormControl';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued'
import { Document, Packer, Paragraph, TextRun, HeadingLevel, AlignmentType, FootnoteReferenceRun } from "docx";
import { saveAs } from "file-saver";
import useHotkeys from "@reecelucas/react-use-hotkeys";
import NavBar from './NavBar/NavBar';
import { db, auth, functions } from "./utils/firebase";
import logo from "./assets/robotedit.png";
import { regexScannos, commonScannos } from './lib/constants/scannos';
import { removeScannedHyphens, getLastCharacter, trimLastCharacter, isPossessiveContraction, trimPossessiveContraction } from './utils/functions';
import { AutoAwesome, Preview, Title } from "@mui/icons-material";
import Refiner from './Refiner/Refiner';
import nlp from 'compromise';
import stringSimilarity from 'string-similarity';
import { Document as PDFDocument, Page as PDFPage, pdfjs } from 'react-pdf';
import { SizeMe } from 'react-sizeme'
import { useWindowHeight, useWindowWidth } from "./lib/hooks";

import { edit } from "ace-builds";
import PDFViewer from "./PDFViewer/PDFViewer";
import { SpinningCircleLoader, NotepadLoader } from 'react-loaders-kit';



function App() {

    const notepadLoaderProps = {
        loading: true,
        pause: false,
        size: 35,
        colors: ['#9F9589', '#9F9589']
    }


    const width = useWindowWidth();
    const height = useWindowHeight();




    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const [paragraphs, setParagraphs] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selected, setSelected] = useState("Bam")
    const [targetParagraphs, setTargetParagraphs] = useState([])
    const [paragraphStyles, setParagraphStyles] = useState([])
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [deleteSnackbarOpen, setDeleteSnackbarOpen] = useState(false)
    const [formatCompletedSnackbarOpen, setFormatCompletedSnackbarOpen] = useState(false);
    const [prompt, setPrompt] = useState("");
    const [showAiPromptEditor, setShowAiPromptEditor] = useState(false);
    const [sourcePara, setSourcePara] = useState("");
    const [aiSource, setAiSource] = useState("");
    const [targetPara, setTargetPara] = useState("");
    const [oldTargetPara, setOldTargetPara] = useState("");
    const [footnotes, setFootnotes] = useState([]);
    const [targetParaIndex, setTargetParaIndex] = useState(0)
    const [suggestedPara, setSuggestedPara] = useState("");
    const [openAIDialog, setOpenAiDialog] = useState(false);
    const [openDiffViewer, setOpenDiffViewer] = useState(false);
    const [showFootnoteEditor, setShowFootnoteEditor] = useState(false);
    const [apiResponse, setApiResponse] = useState("");
    const [loading, setLoading] = useState(false);
    const [suggestedFootnotes, setSuggestedFootnotes] = useState([]);
    const [loadingState, setLoadingState] = useState({});
    const [defaultSource, setDefaultSource] = useState("")
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [formatBtnIndex, setFormatBtnIndex] = React.useState(null);
    const formatMenuOpen = Boolean(anchorEl);
    const inputEl = useRef(null);
    const location = useLocation();
    const [expandedSource, setExpandedSource] = React.useState(false);
    const [expandedParaEditor, setExpandedParaEditor] = React.useState(true);
    const [expandedProofs, setExpandedProofs] = React.useState(false);
    const [deleteFormOpen, setDeleteFormOpen] = React.useState(false);
    const [deleteDisabled, setDeleteDisabled] = React.useState(true);
    const [formOpen, setFormOpen] = React.useState(false);
    const [isAceOpen, setIsAceOpen] = React.useState(false);
    const [pdfFileUrl, setPdfFileUrl] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [tokens, setTokens] = useState(0);
    const [maxTokens, setMaxTokens] = useState(0);
    const [editConfirmation, setEditConfirmation] = useState(false);



    const { content, chapter, section, chaptername, title, book, characters, pdfUrl } = location.state;

    console.log("LOCATIONSTATE2", location.state)
    const navigate = useNavigate();
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const topRef = useRef(null);


    const uid = localStorage.getItem("user");

    const actions = [
        { icon: <AutoFixIcon />, name: 'AI Edit' },
        { icon: <ContentCopyIcon />, name: 'Copy Paragraph' },
        { icon: <CleaningServicesIcon />, name: 'Clean Up' },
        { icon: <DeleteIcon />, name: 'Delete' },
    ];


    const oldCode = `
{
  "name": "Original name",
  "description": null
}
`;

    const newCode = `
{
  "name": "My updated name",
  "description": "Brand new description",
  "status": "running"
}
`;

    const signOutUser = () => {
        signOut(auth).then(() => {
            navigate("/");
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    }

    const handleAccordionSourceChange = (panel) => (event, isExpanded) => {
        setExpandedSource(isExpanded ? panel : false);
    };

    const handleAccordionProofsChange = (panel) => (event, isExpanded) => {
        setExpandedProofs(isExpanded ? panel : false);
    };

    const handleAccordionParaEditorChange = (panel) => (event, isExpanded) => {
        setExpandedParaEditor(isExpanded ? panel : false);
    };

    const handleScrollToTop = () => {
        topRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }


    const handleAceClose = () => {
        setIsAceOpen(false)
    }

    const handleAceOpen = () => {
        setIsAceOpen(true)
    }

    const handleTokenCheck = async () => {
        let tokens, maxTokens, status;

        const docRef = doc(db, "users/" + uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            tokens = docSnap.data().tokens;
            maxTokens = docSnap.data().maxTokens;
            status = docSnap.data().status || "";

            if (tokens <= 0) {
                if (status === 'active') {
                    // Assuming window.location.href should be assigned, not called as a function
                    window.location.href = 'https://billing.stripe.com/p/login/8wM2b3eSd9zS64w7ss';
                } else {
                    navigate("/subscribe");
                }
            }
        } else {
            console.log("No such document!");
        }

        setTokens(tokens);
        setMaxTokens(maxTokens);

        return Number(tokens);
    }

    const getTokenCount = async () => {

        //TODO: TOKENS LOGIC
        let tokens, maxTokens, status

        const docRef = doc(db, "users/" + uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            tokens = docSnap.data().tokens;
            maxTokens = docSnap.data().maxTokens;
            status = docSnap.data().status || "";
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

        setTokens(tokens)
        setMaxTokens(maxTokens)



        return Number(tokens);

    }

    const updateTokenCount = async (tokensUsed) => {

        let tokens = await getTokenCount();

        console.log("TOTESTOKENS", tokens)

        tokens = tokens - tokensUsed;

        console.log("CALCTOKENS", tokens)


        if (tokens <= 0) {
            await setDoc(doc(db, "users/" + uid), { tokens: 0 }, { merge: true });
            return false
        } else {
            setTokens(tokens)
            await setDoc(doc(db, "users/" + uid), { tokens: tokens ? tokens : 0 }, { merge: true });
            return true
        }
    }

    const getChapter = async () => {
        const docRef = doc(db, "users/" + uid + "/books/" + title + "/content/" + content);
        console.log("users/" + uid + "/books/" + title + "/content/" + content);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            handleAccordionSourceChange('panel1', false)
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            handleAccordionSourceChange('panel1', true)
        }
        let chapterParagraphs = docSnap.data().paragraphs.map(a => a.text);
        let chapterParagraphStyles = docSnap.data().paragraphs.map(a => a.style);
        setDefaultSource(chapterParagraphs.join("\n"))
        setTargetParagraphs(chapterParagraphs)
        if (docSnap.data().footnotes) {
            console.log("HAS FOOTNOTES", footnotes)
            setFootnotes(docSnap.data().footnotes)
        }
        setParagraphStyles(chapterParagraphStyles)
        return setParagraphs(chapterParagraphs)
    }

    const newChapter = () => {
        document.getElementById("source").value = "";
        setParagraphs([])
        setTargetParagraphs([])
    }

    useEffect(() => {
        getChapter()
        getTokenCount()
    }, [])


    function removeNonAlphanumericFromEnd(inputString) {
        return inputString.replace(/[^\w\s']*(?<!'s)$/, '');
    }

    const openDrawer = () => {
        setIsDrawerOpen(true);
    };

    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };


    function findClosestMatch(inputName, charactersArr) {

        /* First, create all of the possible name combinations */
        const firstnames = charactersArr.map(element => element.firstname);
        const middlenames = charactersArr.map(element => element.middlename);
        const lastnames = charactersArr.map(element => element.lastname);
        const nicknames = charactersArr.map(element => element.nickname);
        const firstmiddlenames = charactersArr.map(element => element.firstname + " " + element.middlename);
        const firstlastnames = charactersArr.map(element => element.firstname + " " + element.lastname);
        const fullnames = charactersArr.map(element => element.firstname + " " + element.middlename + " " + element.lastname);

        /* Add plural and possessive variations */
        let combinedNames = firstnames.concat(middlenames, lastnames, nicknames, firstmiddlenames, firstlastnames, fullnames)
        combinedNames.forEach((name, index) => {
            if (name) {
                name.trim()
                combinedNames.push(name + "'s")
                combinedNames.push(name + "s")
            }
        })
        /* Remove blank / malformed */
        combinedNames = combinedNames.filter(item => !(item.endsWith(" 's") || item.endsWith(" s")));


        const isValidValue = (value) => value !== undefined && value !== 'undefined' && value.trim() !== '';
        const uniqueFilteredArr = combinedNames
            .map(item => item && item.split('undefined').filter(isValidValue).join(' ').trim())
            .filter(isValidValue)
            .filter((value, index, self) => self.indexOf(value) === index)
            .filter(n => n)
        let editedName = inputName
        const matches = stringSimilarity.findBestMatch(editedName, uniqueFilteredArr);
        const lastCharacter = getLastCharacter(editedName);
        let isPlural = false;
        let isContraction = false;
        /* This check is specifically for plural proper nouns. We do not want possessive nouns */
        if (lastCharacter == "s" && !isPossessiveContraction(editedName)) {
            isPlural = true;
            console.log("plur name", editedName)
            editedName = trimLastCharacter(editedName);
        }
        if (isPossessiveContraction(editedName)) {
            isContraction = true;
            console.log("poss const");
            editedName = trimPossessiveContraction(inputName)
            console.log(editedName)
        }
        let closestMatch = matches.bestMatch.target;
        const similarityScore = stringSimilarity.compareTwoStrings(editedName, closestMatch);
        console.log(editedName, closestMatch, similarityScore)
        // If the match isn't very good, probably don't replace
        /* Reassemble plural and possessive */
        if (isPlural) {
            closestMatch = closestMatch + "s"
        }
        if (isContraction && !isPossessiveContraction(closestMatch)) {
            closestMatch = closestMatch + "'s"
        }
        if (similarityScore < 0.5) {
            console.log('lowscore', inputName)
            return inputName
        }
        return closestMatch;
    }

    function removeAlphanumeric(inputString) {
        return inputString.replace(/[^a-zA-Z0-9\s]/g, '');
    }

    const parseText = async (charactersArr, aiWillEdit) => {
        /* First, get the RAW source to display in the source column without any refinement */
        let rawSource = document.getElementById("source").value;
        /* Declare the source variable where the processing will take place */
        let source = rawSource
        /* Immediately replace incorrect apostrophes for name recognition purposes */
        source = source.replace("’", "'")

        /* Remove scanned hyphens */
        source = removeScannedHyphens(source);


        /* Set up custom names set from Contents page */
        let myWords = {}
        setParagraphs([]);


        if (charactersArr.length > 0 && !charactersArr.every(item => item === '')) {
            charactersArr.forEach(element => {
                if (element.firstname) {
                    myWords[element.firstname] = 'FirstName'
                }
                if (element.nickname) {
                    myWords[element.nickname] = 'FirstName'
                }
                if (element.middlename) {
                    myWords[element.middlename] = 'FirstName'
                }
                if (element.lastname) {
                    myWords[element.lastname] = 'LastName'
                }
            })

            console.log("MYWORDS", myWords)
            /* Use nlp to find what it thinks are people */
            var arr = nlp(source, myWords).people().data();
            console.log("RAWPEEPS", arr)
            /* Get only unique names found */
            let people = Array.from(new Set(arr.map(function (w) { return w.text })))
            /* Function to detect capitalized names only */
            function isCapitalized(str) {
                return /^[A-Z]/.test(str);
            }
            /* For the second nlp sweep, find proper nouns */
            let properNouns = nlp(source).match('#ProperNoun').out('array')
            people = people.concat(properNouns)
            /* Filter out the non-capitalized names */
            let capitalizedElements = people.filter(isCapitalized);

            console.log("NAMESASDF")
            console.log("before an trim", capitalizedElements)
            capitalizedElements = capitalizedElements.map(element => removeNonAlphanumericFromEnd(element))
            console.log("capitalized", capitalizedElements)

            /* Perform the actual matches */
            let correctedNames = capitalizedElements.map(element => findClosestMatch(element, location.state.characters));
            console.log('corrnames', correctedNames)
            capitalizedElements.forEach((element, index) => {
                console.log(element, correctedNames[index])
                const regex = new RegExp(element, "g");
                source = source.replace(regex, correctedNames[index])
            })
        }


        for (const pattern in regexScannos) {
            if (regexScannos.hasOwnProperty(pattern)) {
                const replacement = regexScannos[pattern];
                const regex = new RegExp(pattern, "g");
                source = source.replace(regex, replacement);
            }
        }

        for (const scanno in commonScannos) {
            source = source.replace(scanno, commonScannos[scanno]);
        }

        const splitParagraphs = source.split('\n\n');
        const splitRawParagraphs = rawSource.split('\n\n')

        let finalParas = splitParagraphs.map(element => {
            console.log(element)
            var output = element.replace(/[\n\r]+/g, ' ').replace(/\s{2,}/g, ' ').replace(/^\s+|\s+$/, '').replace(/ -/g, '').replace(/¬ /g, '')
            return output
        })

        let paragraphStyleArr =
            finalParas.map(element => {
                return {
                    bold: false,
                    quote: false,
                    italics: false,
                    centered: false,
                    subHeader: false
                }
            })
            ;

        // let aiWillEdit = window.confirm("Allow RoboEdit to make the first round of edits?")

        if (aiWillEdit) {

            setEditConfirmation(false)

            // alert("About to handle token check")

            //TODO: TOKENS LOGIC
            let tokens = await handleTokenCheck();

            console.log(tokens)

            if (!tokens) {
                return
            }

            setIsLoading(true)

            const inputLen = finalParas.length;


            console.log("FINALPARASPROOF", finalParas.length, finalParas)

            const textToProofread = finalParas.join('\n\n');

            console.log("TEXTTOPROOF", textToProofread.length, textToProofread)

            // Create prompt for OpenAI API
            const prompt = textToProofread;

            // Set loading state
            setLoading(true);

            try {

                const claudeProofread = httpsCallable(functions, 'callClaude');

                const result = await claudeProofread({ userInput: prompt });

                console.log("CALL_CLAUDE", result)

                // Handle response
                const proofreadText = result.data.result;
                const tokensUsed = result.data.tokenUsage.totalTokens;

                updateTokenCount(tokensUsed)

                console.log("RAWPROOFREADPARA", proofreadText)



                // Split proofread text back into paragraphs
                let proofreadParas = proofreadText.split('\n\n');

                console.log("PREINPUTLENCHECK", proofreadParas.length, proofreadParas)

                // Ensure the number of paragraphs match
                while (inputLen !== proofreadParas.length) {
                    if (inputLen < proofreadParas.length) {
                        // Remove extra paragraphs from proofreadParas
                        if (proofreadParas[0].includes("Here's the corrected") ||
                            proofreadParas[0].includes("with spelling errors fixed")) {
                            // Remove first para
                            proofreadParas = proofreadParas.slice(1);
                        } else {
                            proofreadParas.pop();
                        }
                    } else {
                        // Add empty paragraphs to proofreadParas
                        proofreadParas.push("");
                    }
                }


                console.log("PROOFREADPARA", proofreadParas.length, proofreadParas)
                console.log('before assignment', proofreadParas)
                finalParas = proofreadParas;
                setIsLoading(false)


            } catch (error) {
                setIsLoading(false)

                if (error.response && error.response.data && error.response.data.error) {
                    // Check if the error message contains information about input length
                    const errorMessage = error.response.data.error.message;
                    if (errorMessage.includes("input length")) {
                        // Inform the user that the input length exceeds the maximum allowed
                        console.error("Error: Maximum input length exceeded. Please shorten your prompt.");
                        alert("Error: Maximum input length exceeded. Initial AI edit not available for this section.");
                    } else {
                        // Handle other errors
                        console.error("Error:", errorMessage);
                        alert("Error:", errorMessage);

                    }
                } else {
                    // Handle other errors
                    alert("Error:", error.message);
                }
            }
        }

        setEditConfirmation(false)
        setParagraphStyles(paragraphStyleArr)
        setTargetParagraphs(finalParas)
        setParagraphs(splitRawParagraphs);
        const section = document.querySelector('#panel1bh-header');
        section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        window.scrollBy(0, 500);
        handleAccordionParaEditorChange('panel1')
    }



    const deletePara = (index, paragraphs, targetParagraphs, paragraphStyles) => {
        let newParaArr = [...paragraphs];
        let newTargetParaArr = [...targetParagraphs]
        setTargetParagraphs(newTargetParaArr)
        console.log("newParaArr", newParaArr)
        let newParaStylesArr = [...paragraphStyles];
        newParaArr.splice(index, 1);
        newParaStylesArr.splice(index, 1);
        newTargetParaArr.splice(index, 1);
        console.log(newParaStylesArr)
        setParagraphs(newParaArr)
        setParagraphStyles(newParaStylesArr);
        setDeleteSnackbarOpen(true)
    }

    const deleteFootnote = (index, footnotes) => {
        let newFootnoteArr = [...footnotes];
        newFootnoteArr.splice(index, 1);
        setFootnotes(newFootnoteArr)
    }

    const saveTargetParagraph = (index) => {
        const paraText = document.getElementById("target_" + index).value;
        targetParagraphs[index] = paraText;
        console.log("SAVING TARGET", targetParagraphs)
        setTargetParagraphs(targetParagraphs);
        compileParagraphs();
    }

    const generateParagraphs = (paragraphs, targetParagraphs, paragraphStyles) => {
        return (
            paragraphs.map((element, index) => {

                return (
                    <tr key={index}>
                        <td style={{ verticalAlign: 'top' }}><p id={"source_" + index} key={"sourcekey_" + index} className="Source">{element}</p></td>
                        <td style={{ paddingTop: '5px', paddingBottom: '10px' }}>
                            <textarea
                                style={{ fontSize: '14pt', padding: '0px 0px 0px 5px' }}
                                onBlur={() => saveTargetParagraph(index)}
                                key={"targetkey_" + (10000 + Math.random() * (1000000 - 10000))} spellCheck="true" id={"target_" + index}
                                className="Target"
                                defaultValue={targetParagraphs[index]}>
                            </textarea></td>
                        <td style={{ padding: '5px', verticalAlign: 'top', backgroundColor: 'none', boxShadow: 'none' }}>
                            <br></br>
                            <AssistButton tip='Edit this paragraph with AI' key={'assistbtn_' + index} id={'assistbtn_' + index} icon={<AutoFixIcon />} index={index} loadingState={loadingState} clicked={() => formatText(index)} />
                            <br></br><br></br>

                            {<AssistButton
                                isIconBtn tip='View Before/After Edit Comparison'
                                key={'diffbtn_' + index}
                                id={'diffbtn_' + index}
                                icon={<DifferenceIcon />}
                                index={index}
                                enabled={!loadingState}
                                clicked={() => handleViewDiff(index, element)}>
                                <DifferenceIcon />
                            </AssistButton>}
                            <br></br><br></br>
                            {/* {<AssistButton
                                isIconBtn
                                tip='Review Paragraph Proof in Readable Format'
                                key={'proofbtn_' + index}
                                id={'proofbtn_' + index}
                                icon={<PreviewIcon />}
                                index={index}
                                enabled={!loadingState}
                                clicked={() => handleViewDiff(index, element)}>
                                <PreviewIcon />
                            </AssistButton>} */}
                            <br></br><br></br>

                            <div >
                                <SpeedDial
                                    ariaLabel="SpeedDial"
                                    direction='down'
                                    sx={{
                                        '& .MuiFab-primary': {
                                            width: 32,
                                            height: 30,
                                            color: 'black',
                                            backgroundColor: '#f2f2f2' // Change this to the desired color
                                        }
                                    }}
                                    icon={<MoreIcon fontSize="small" />}
                                >
                                    <SpeedDialAction
                                        sx={{ width: 30, height: 30, backgroundColor: 'primary' }}
                                        key={'review'}
                                        onClick={() => goToProofsPara(index)}
                                        icon={<PreviewIcon fontSize="small" />}
                                        tooltipTitle={"Review Proofs"}
                                    />
                                    <SpeedDialAction
                                        sx={{ width: 30, height: 30, backgroundColor: 'primary' }}
                                        key={'copy'}
                                        icon={<ContentCopyIcon fontSize="small" />}
                                        tooltipTitle={"Copy"}
                                    />
                                    <SpeedDialAction
                                        sx={{ width: 30, height: 30, backgroundColor: 'white' }}
                                        key={'delete'}
                                        onClick={() => deletePara(index, paragraphs, targetParagraphs, paragraphStyles)}
                                        icon={<DeleteIcon fontSize="small" color="warning" />}
                                        tooltipTitle={"Delete"}
                                    />
                                </SpeedDial>
                                <Tooltip placement="top" title="Insert paragraph">
                                    <IconButton size="small" startIcon={<PlusIcon />} sx={{
                                        backgroundColor: 'var(--button-background-color)',
                                        color: 'var(--button-text-color)',
                                        '&:hover': {
                                            backgroundColor: 'var(--button-background-color)',
                                        },
                                    }}
                                        variant="contained"
                                        onClick={() => newMiddleParagraph(index + 1)}>
                                        <PlusIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            {/* <AssistButton key={'assistbtn_' + index} id={'assistbtn_' + index} text="Edit" icon={<AutoFixIcon />} index={index} loadingState={loadingState} clicked={() => formatText(index)} />
                            <br></br><br></br>
                            <IconButton size="small" startIcon={<DeleteIcon />} color="error" variant="contained" onClick={() => deletePara(index, paragraphs)}><DeleteIcon fontSize="small" /></IconButton> */}
                        </td>

                    </tr>

                );
            })
        );
    };

    // const updateText = (e, index) => {
    //     console.log("Update " + index)
    //     let updateParas = paragraphs;
    //     updateParas[index] = e.target.value;
    //     console.log(updateParas);
    //     setParagraphs(updateParas);
    // }

    const handleOpenAdditionalPromptModal = () => {
        // handleFormatCompletedSnackbarClose();
        setOpenAiDialog(true)
    }

    const handleOpenDiffViewer = () => {
        // handleFormatCompletedSnackbarClose();
        setOpenDiffViewer(true)
    }

    const handleViewDiff = async (index, source) => {
        let target = document.getElementById("target_" + index).value;
        let sourceText = source.replace(/\r?\n|\r/g, "");
        console.log(sourceText, target);

        setTargetPara(sourceText);
        setSuggestedPara(target)
        setAiSource("paragraph")
        // setFormatCompletedSnackbarOpen(true);
        // alert("Setting text");
        // setTargetParagraphs(targetParagraphs);
        handleOpenDiffViewer()
    }


    const formatText = async (index, isFootnote) => {

        let tokens = await handleTokenCheck();

        if (!tokens) {
            return
        }


        setLoadingState(index);
        let target = isFootnote
            ? document.getElementById("footnote_" + index).value
            : document.getElementById("target_" + index).value;
        setOldTargetPara(target);
        setTargetPara(target)
        setTargetParaIndex(index)
        console.log(target);
        let charactersRaw = characters.map(element => element.firstname + " " + element.middlename + " " + element.lastname);
        let charactersStr = charactersRaw.join(', ');
        console.log("CHARSARR", charactersStr)
        setLoading(true);
        const claudeProofread = httpsCallable(functions, 'callClaude');
        const result = await claudeProofread({ userInput: target });
        console.log("CALL_CLAUDE", result)

        // Handle response
        const tokensUsed = result.data.tokenUsage.totalTokens;
        updateTokenCount(tokensUsed)

        let sourceParas = [...paragraphs];
        console.log("SOURCE PARAS", sourceParas);
        let suggestedText = result.data.result;
        console.log(suggestedText)
        suggestedText = suggestedText.replace(/\s+/g, ' ').trim();
        setSuggestedPara(suggestedText);
        if (isFootnote) {
            footnotes[index] = suggestedText;
            console.log(footnotes[index])
            setLoadingState(false);
            setFootnotes(footnotes);
            setAiSource("footnote")
        } else {
            targetParagraphs[index] = suggestedText;
            console.log(targetParagraphs[index])
            paragraphs[index] = paragraphs[index];
            console.log(paragraphs[index])
            setLoadingState(false);
            setParagraphs(sourceParas);
            setAiSource("paragraph")
        }
        // setFormatCompletedSnackbarOpen(true);
        // alert("Setting text");
        // setTargetParagraphs(targetParagraphs);
        handleOpenAdditionalPromptModal()
    }

    const footnoteAssist = () => {
        alert("test")
    }

    const compileParagraphs = () => {
        const table = document.getElementById("table");
        const totalRowCount = table.rows.length;
        const paragraphCount = totalRowCount - 1;
        let paragraphArr = [];
        for (let i = 0; i < paragraphCount; i++) {
            let para = document.getElementById(String("target_" + i)).value;
            para = smartquotesString(para)
            paragraphArr.push(para)
        }
        setTargetParagraphs(paragraphArr)
    }

    const compileFootnotes = () => {
        const table = document.getElementById("footnote-table");
        const footnoteCount = table.rows.length;
        let footnoteArr = [];
        for (let i = 0; i < footnoteCount; i++) {
            let para = document.getElementById(String("footnote_" + i)).value;
            para = smartquotesString(para)
            footnoteArr.push(para)
        }
        setFootnotes(footnoteArr)
    }

    const toggleStyle = (index, paragraphStyles, format) => {
        handleFormatMenuClose()
        let styleObj = paragraphStyles[index]
        let newParaStylesArr = [...paragraphStyles];

        if (newParaStylesArr[index][format]) {
            styleObj[format] = false;
            newParaStylesArr[index] = styleObj;
            setParagraphStyles(newParaStylesArr);
        } else {
            styleObj[format] = true;
            newParaStylesArr[index] = styleObj;
            setParagraphStyles(newParaStylesArr);
        }
        console.log(styleObj)
    }

    const createFootnote = (index) => {
        let term = window.prompt("Term for footnote");
        console.log(term);
    }

    const copyToClipboard = (_) => {
        let dataArr = targetParagraphs.map((element, index) => {
            return document.getElementById('targetparakey_' + index).outerHTML;
        })
        let footnoteArr = footnotes.map((element, index) => {
            return document.getElementById('prooffootnote_' + index).outerHTML;
        })
        dataArr.unshift('<h2>' + chaptername + '</h2>');
        if (footnotes) {
            dataArr = [...dataArr, "<b>FOOTNOTES - You must format these manually</b><br><br>", footnoteArr];
        }
        console.log(dataArr);
        const data = dataArr.join('\n\n');
        const blob = new Blob([data], { type: 'text/html' });
        const clipboardItem = new ClipboardItem({ 'text/html': blob });
        navigator.clipboard.write([clipboardItem]);
    }

    const handleFormatMenuClick = (event, index) => {
        setAnchorEl(event.currentTarget);
        setFormatBtnIndex(index);
    };
    const handleFormatMenuClose = () => {
        setAnchorEl(null);
    };

    const determineFootnoteNumber = (targetParagraphs) => {
        let num = 0
        targetParagraphs.forEach(element => {
            if (element.includes("<sup>")) {
                num = num + (element.match(/is/g) || []).length;
            }
        })
        alert(num)
    }


    const generateTargetParagraphs = (targetParagraphs, paragraphStyles, chaptername) => {
        return (
            <table id="proof-table" className="ProofTable">
                <thead style={{ color: 'var(--text-color)' }} align="center"><h3>{chaptername}</h3></thead>
                <tbody>
                    {targetParagraphs.map((element, index) => {
                        var refinedText = element.replace(/\n/g, "<br>");
                        let style = paragraphStyles[index];
                        let styleSx = {};
                        let textVariant = 'body1';
                        let alignment = 'left';
                        /* Italics logic */
                        if (style.italics) {
                            styleSx["fontStyle"] = 'italic'
                        }
                        /* Bold logic */
                        if (style.bold) {
                            styleSx["fontWeight"] = 'bold'
                        }
                        /* Quotation logic */
                        if (style.quote) {
                            textVariant = 'body2'
                        }
                        /* Centered text logic */
                        if (style.center) {
                            alignment = 'center'
                        }
                        if (style.subHeader) {
                            alignment = 'center';
                            styleSx["fontWeight"] = 'bold'
                        }
                        return (<tr key={"targetpararow_" + index}>
                            <td>
                                <Typography align={alignment} variant={textVariant} sx={styleSx} className={"TargetPara"} id={"targetparakey_" + index} key={"targetparakey_" + index} dangerouslySetInnerHTML={{ __html: refinedText }} />
                            </td>
                            <td>
                                <IconButton
                                    sx={{
                                        padding: 3,
                                        color: 'var(--text-color)'
                                    }}

                                    key={"formatbtn_" + index}
                                    ref={inputEl}
                                    id={"formatbtn_" + index}
                                    aria-controls={formatMenuOpen ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={formatMenuOpen ? 'true' : undefined}
                                    onClick={(e) => handleFormatMenuClick(e, index)}
                                >
                                    <MoreIcon fontSize="small" />
                                </IconButton>
                                <Menu
                                    key={"formatmenu_" + index}
                                    sx={{ fontSize: '7px' }}
                                    id="demo-positioned-menu"
                                    aria-labelledby="demo-positioned-button"
                                    anchorEl={anchorEl}
                                    open={formatMenuOpen}
                                    onClose={handleFormatMenuClose}
                                    MenuListProps={{
                                        'dense': true,
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => goToTextarea(formatBtnIndex)}>
                                        <ListItemIcon>
                                            <EditIcon fontSize="inherit" />
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ fontSize: '12px' }} >Edit Text</ListItemText>
                                    </MenuItem>
                                    <Divider />

                                    <MenuItem onClick={() => toggleStyle(formatBtnIndex, paragraphStyles, "bold")}>
                                        <ListItemIcon>
                                            <FormatBoldIcon fontSize="xs" />
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ fontSize: '12px' }} >Bold</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={() => toggleStyle(formatBtnIndex, paragraphStyles, "italics")}>
                                        <ListItemIcon>
                                            <FormatItalicIcon fontSize="xs" />
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ fontSize: '12px' }} >Italicize</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={() => toggleStyle(formatBtnIndex, paragraphStyles, "center")}>
                                        <ListItemIcon>
                                            <CenterIcon fontSize="inherit" />
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ fontSize: '12px' }} >Center</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={() => toggleStyle(formatBtnIndex, paragraphStyles, "quote")}>
                                        <ListItemIcon>
                                            <FormatQuoteIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ fontSize: '12px' }}>Make Quote</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={() => toggleStyle(formatBtnIndex, paragraphStyles, "subHeader")}>
                                        <ListItemIcon>
                                            <TitleIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ fontSize: '12px' }}>Make SubHeader</ListItemText>
                                    </MenuItem>
                                </Menu>
                                {/* <Button onClick={() => goToTextarea(index)} endIcon={<EditIcon />}><i></i></Button>
                                <Button onClick={() => makeItalic(index)} endIcon={<FormatItalicIcon />}><i></i></Button>
                                <Button onClick={() => makeQuote(index)} endIcon={<FormatQuoteIcon />}><i></i></Button> */}
                                {/* <Button onClick={() => createFootnote(index)} endIcon={<Filter1Icon />}><i></i></Button> */}
                            </td>
                        </tr>)
                    })
                    }
                </tbody>
            </table >
        )
    }

    function smartquotesString(str) {
        return str
            .replace(/'''/g, '\u2034')                                                   // triple prime
            .replace(/(\W|^)"(\S)/g, '$1\u201c$2')                                       // beginning "
            .replace(/(\u201c[^"]*)"([^"]*$|[^\u201c"]*\u201c)/g, '$1\u201d$2')          // ending "
            .replace(/([^0-9])"/g, '$1\u201d')                                            // remaining " at end of word
            .replace(/''/g, '\u2033')                                                    // double prime
            .replace(/(\W|^)'(\S)/g, '$1\u2018$2')                                       // beginning '
            .replace(/([a-z])'([a-z])/ig, '$1\u2019$2')                                  // conjunction's possession
            .replace(/((\u2018[^']*)|[a-z])'([^0-9]|$)/ig, '$1\u2019$3')                 // ending '
            .replace(/(\u2018)([0-9]{2}[^\u2019]*)(\u2018([^0-9]|$)|$|\u2019[a-z])/ig, '\u2019$2$3')     // abbrev. years like '93
            .replace(/(\B|^)\u2018(?=([^\u2019]*\u2019\b)*([^\u2019\u2018]*\W[\u2019\u2018]\b|[^\u2019\u2018]*$))/ig, '$1\u2019') // backwards apostrophe
            .replace(/'/g, '\u2032');
    };

    const saveAndUpload = async (targetParagraphs, footnotes, paragraphStyles) => {
        console.log(paragraphStyles)
        let refinedParagraphs = targetParagraphs.map(element => {
            return element.replace(/\n/g, "<br>");
        })
        let paragraphsArr = refinedParagraphs.map((element, index) => {
            return {
                text: element,
                style: paragraphStyles[index]
            }
        })
        console.log(paragraphsArr);
        let data = { paragraphs: paragraphsArr, footnotes: footnotes }
        return await setDoc(doc(db, "users/" + uid + "/books/" + title + "/content/" + content), data).then(setSnackbarOpen(true))
    }


    const goToTextarea = (id) => {
        handleFormatMenuClose()
        const fullId = "target_" + id;
        setTimeout(function () {
            document.getElementById(fullId).focus()
        }, 0);

    }

    const goToProofsPara = (id) => {
        setExpandedProofs(true)
        const fullId = "targetparakey_" + id;
        var element = document.getElementById(fullId);
        setTimeout(function () {
            if (element) {
                // Scroll into view
                element.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center'
                });
            }
        }, 0);

    }

    const newParagraph = () => {
        let newSourceParaArray = [...paragraphs, ""];
        const newTargetParaArray = [...targetParagraphs, ""];
        const newStylesParaObj = [...paragraphStyles, {
            bold: false,
            quote: false,
            italics: false,
            centered: false,
            subHeader: false
        }];
        setParagraphs(newSourceParaArray)
        setParagraphStyles(newStylesParaObj)
        setTargetParagraphs(newTargetParaArray)
    }

    const newMiddleParagraph = (index) => {
        let newSourceParaArray = [...paragraphs];
        let newTargetParaArray = [...targetParagraphs];
        let newStylesParaObj = [...paragraphStyles];

        newSourceParaArray.splice(index, 0, "");
        newTargetParaArray.splice(index, 0, "");
        newStylesParaObj.splice(index, 0, {
            bold: false,
            quote: false,
            italics: false,
            centered: false,
            subHeader: false
        });

        setParagraphs(newSourceParaArray)
        setParagraphStyles(newStylesParaObj)
        setTargetParagraphs(newTargetParaArray)
    }

    const newFootnote = () => {
        determineFootnoteNumber(targetParagraphs)
        let newFootnoteArr = [...footnotes, ""];
        setFootnotes(newFootnoteArr)
    }

    const cleanUpText = (type, index) => {
        let source = document.getElementById(type + '_' + index).value;
        source = source.replace(/[\n\r]+/g, ' ').replace(/\s{2,}/g, ' ').replace(/^\s+|\s+$/, '').replace(/-/g, '')
        document.getElementById(type + '_' + index).value = source
    }

    const generateFootnoteTextareas = (footnotes) => {
        return (
            <table id="footnote-table" className="FootnoteTable">
                <tbody>
                    {footnotes.map((element, index) => {
                        const keynum = (10000 + Math.random() * (1000000 - 10000))
                        return (
                            <tr>
                                <td>
                                    <p style={{ cursor: 'pointer' }} key={"footnotenumkey_" + keynum}>{index + 1}</p>
                                </td>
                                <td>
                                    <textarea key={"footnoteKey_" + keynum} id={"footnote_" + index} className="FootnoteTextarea" onBlur={() => updateFootnotes(index)} defaultValue={footnotes[index]} />
                                </td>
                                <td>
                                    <AssistButton text="AI Assist" key={"assistbtn_" + keynum} index={index} loadingState={loadingState} clicked={() => formatText(index, true)} />
                                    <br></br><br></br>
                                    <Button key={"cleanup_" + keynum} size="small" endIcon={<CleaningServicesIcon />} color="warning" variant="contained" onClick={() => cleanUpText("footnote", index)}>Clean Up</Button>
                                    <br></br><br></br>
                                    <Button key={"delete_" + keynum} size="small" endIcon={<DeleteIcon />} color="error" variant="contained" onClick={() => deleteFootnote(index, footnotes)}>Delete</Button>
                                </td>
                            </tr>)
                    })}
                </tbody>
            </table>
        )
    }

    const renderFootnotes = (footnotes) => {
        return footnotes.map((element, index) => {
            console.log(element);
            let text = '<sup>' + (index + 1) + '</sup> ' + element
            return (
                <p id={"prooffootnote_" + index} className="ProofFootnote" dangerouslySetInnerHTML={{ __html: text }} />
            )
        })
    }


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleFormClose = () => {
        setFormOpen(false);
    }

    const handleDeleteSnackbarClose = () => {
        setDeleteSnackbarOpen(false);
    };

    const handleFormatCompletedSnackbarClose = () => {
        setFormatCompletedSnackbarOpen(false);
    };

    const saveDoc = (targetParagraphs, paragraphStyles, chaptername) => {
        /* Set chapter title */
        const chapterTitle = new Paragraph({
            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,
            children: [
                new TextRun(chaptername),
                new TextRun({
                    break: 1,
                })
            ],
        })
        const chapterContent = targetParagraphs.map((element, index) => {

            let stylesObj = paragraphStyles[index];
            let isBold = stylesObj["bold"];
            let isItalics = stylesObj["italics"];
            let isQuote = stylesObj["quote"];
            let isSubHeader = stylesObj["subHeader"];


            let paragraphStyle = isQuote ? 'quoteStyle' : 'normal';

            // Function to parse <i> tags and create appropriate TextRun objects
            const parseParagraph = (text) => {
                const parts = text.split(/(<i>.*?<\/i>)/g); // Split text by <i> tags
                return parts.map(part => {
                    if (part.startsWith('<i>') && part.endsWith('</i>')) {
                        // Italicized part
                        return new TextRun({
                            text: part.substring(3, part.length - 4), // Remove <i> and </i> tags
                            italics: true,
                        });
                    } else {
                        // Normal text
                        return new TextRun({
                            text: part,
                            italics: isItalics,
                            bold: isBold
                        });
                    }
                });
            };

            const textRuns = parseParagraph(element);

            // Add line break after each paragraph
            textRuns.push(new TextRun({
                break: 1,
            }));

            return new Paragraph({
                style: paragraphStyle,
                children: textRuns,
            });
        });

        const chapterArr = [chapterTitle, ...chapterContent];

        console.log(chapterArr);

        const doc = new Document({
            styles: {
                paragraphStyles: [
                    {
                        id: "quoteStyle",
                        name: "Quotation",
                        basedOn: "Normal",
                        next: "Normal",
                        quickFormat: true,
                        paragraph: {
                            size: 16,
                            indent: {
                                left: 720,
                            },
                        }
                    },
                    {
                        id: "subHeaderStyle",
                        name: "SubHeader",
                        basedOn: "heading3",
                        next: "Normal",
                        quickFormat: true,
                        paragraph: {
                            size: 24,
                            alignment: AlignmentType.CENTER,
                        }
                    }],
            },
            footnotes: {
                1: { children: [new Paragraph("My amazing reference")] },
            },
            sections: [
                {
                    properties: {},
                    children: chapterArr
                    ,
                },
            ],
        });

        // Used to export the file into a .docx file
        Packer.toBlob(doc).then(blob => {
            console.log(blob);
            saveAs(blob, chaptername + '.docx');
            console.log("Document created successfully");
        });
    }

    const deleteElement = async () => {

        let updatedBook = book;
        console.log(updatedBook)
        let elementArr, newArr;
        console.log(section, content)

        switch (section) {
            case "None":
                elementArr = updatedBook["bookLayout"]["body"]["sections"][0]["chapters"];
                newArr = elementArr.filter((obj) => obj.id !== content)
                updatedBook["bookLayout"]["body"]["sections"][0]["chapters"] = newArr;
                break;
            case "frontMatter":
            case "backMatter":
                elementArr = updatedBook["bookLayout"][section];
                newArr = elementArr.filter((obj) => obj.id !== content)
                updatedBook["bookLayout"][section] = newArr;
                break;
            default:
                let sectionsArr = updatedBook["bookLayout"]["body"]["sections"];
                let sectionIndex = sectionsArr.findIndex(item => item.name === section);
                elementArr = updatedBook["bookLayout"]["body"]["sections"][sectionIndex]["chapters"];
                newArr = elementArr.filter((obj) => obj.id !== content)
                updatedBook["bookLayout"]["body"]["sections"][sectionIndex]["chapters"] = newArr;
        }

        await deleteDoc(doc(db, "users/" + uid + "/books/" + title + "/content/" + content))
            .then(
                await updateDoc(doc(db, "users/" + uid + "/books/" + book.title), {
                    bookLayout: updatedBook["bookLayout"]
                }
                ).then(navigate("/books/contents", {
                    state: {
                        book: updatedBook
                    }
                })
                )
            )
    }

    const handleAcceptAiDialog = (targetParaIndex, aiSource) => {
        let newTargetParaArray;
        console.log(aiSource);
        if (aiSource === "footnote") {
            newTargetParaArray = footnotes
        }
        if (aiSource === "paragraph") {
            newTargetParaArray = targetParagraphs
        }
        if (document.getElementById('ai-target-textarea')) {
            const manuallyEditedText = document.getElementById('ai-target-textarea').value;
            newTargetParaArray[targetParaIndex] = manuallyEditedText
        } else {
            newTargetParaArray[targetParaIndex] = suggestedPara;
        }
        if (aiSource === "footnote") {
            setFootnotes(newTargetParaArray)
        } else {
            // setFootnotes([...footnotes, suggestedFootnotes]);
            console.log(newTargetParaArray)
            setTargetParagraphs(newTargetParaArray)
        }
        setOpenAiDialog(false)
        setShowFootnoteEditor(false)
        setShowAiPromptEditor(false)
        saveAndUpload(newTargetParaArray, footnotes, paragraphStyles);
    }

    const handleCancelAiDialog = (aiSourceType) => {
        let newArr;
        if (aiSourceType === "footnote") {
            newArr = footnotes;
            newArr[targetParaIndex] = targetPara;
            setFootnotes(newArr);
        }
        if (aiSourceType === "paragraph") {
            newArr = targetParagraphs;
            // alert(oldTargetPara)
            newArr[targetParaIndex] = oldTargetPara;
            setTargetParagraphs(newArr);
        }
        setOpenAiDialog(false)
        setShowAiPromptEditor(false)
    }

    const handleCloseDiffViewer = () => {
        setOpenDiffViewer(false)
    }

    const openDeleteForm = () => {
        setDeleteFormOpen(true);
    }

    const checkDeleteTitle = () => {
        if (document.getElementById('delete').value === chaptername) {
            setDeleteDisabled(false)
        }
    }

    const handleDeleteFormClose = () => {
        setDeleteFormOpen(false);
    }

    const additionalPrompt = async (instruction) => {
        let prompt = document.getElementById('additional-prompt-textfield').value;
        let term;
        switch (instruction) {
            case 'specific':
                prompt = prompt + ':\n\n' + targetPara
                break;
            case 'spellcheck':
                prompt =
                    `Spell check the following text:

                ` + targetPara
                break;
            case 'title':
                prompt = `Wrap anything that should be italicized in i tags:
                `
                    + targetPara
                break;
            case 'footnote':
                term = window.prompt("Enter term for foonote");
                prompt = `Spell check the paragraph below and create a footnote for the term "${term}". If the term is a person, structure the footnote like this: "<Full Name> (<year of birth>-<year of death>)" followed by information obtained from the web. Do not include bibliographic information, only informative text. Do not include information from the paragraph provided in the prompt. Only use information from the web. The final text returned should consist of, the original, spell-checked paragraph with the footnote number in <sup> tags at the end of the sentence in which the term appears. These <sup> tags should come after the period at the end of the sentence. After this paragraph, provide the footnote text separated by #. Do not include the footnote number in the footnote itself or in the original paragraph. Do not add anything to the beginning of the returned text. If the footnote contains another person's name, add their year of birth and year of death in parentheses after their name in the foonote. For example: <Full Name> (<year of birth>-<year of death>) was a famous writer and the son of <Some other person> (<year of birth>-<year of death>). This is the original paragraph: \n\n
                `
                    + targetPara
                break;
            default:
                if (prompt == null || prompt.match(/^\s*$/) !== null) {
                    return alert("Empty prompt")
                }
                prompt = prompt;
        }

        const claudeProofread = httpsCallable(functions, 'callClaude');

        const result = await claudeProofread({ userInput: prompt });

        console.log("CALL_CLAUDE", result)

        // Handle response
        let suggestedText = result.data.result;
        const tokensUsed = result.data.tokenUsage.totalTokens;

        updateTokenCount(tokensUsed)

        if (instruction === "footnote") {
            setShowFootnoteEditor(true);
            suggestedText = suggestedText.replace(/\s+/g, ' ').trim();
            let para = suggestedText.substring(0, suggestedText.indexOf("#")).trim();
            console.log('PARAGRAPH', para)
            let footnote = suggestedText.split('#')[1];
            setSuggestedPara(para)
            return setSuggestedFootnotes([footnote]);
        }
        suggestedText = suggestedText.replace(/\s+/g, ' ').trim();
        setSuggestedPara(suggestedText);
        // alert("Setting text" + suggestedText);
        return setShowAiPromptEditor(true)


        // alert(prompt);
    }


    const action = (
        <React.Fragment>
            <Button variant="contained" color="primary" size="small" onClick={() => handleOpenAdditionalPromptModal()}>
                Additional Prompt
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleFormatCompletedSnackbarClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    const showManualEditor = () => {
        setShowAiPromptEditor(true);
        const section = document.querySelector('#bottom-of-editor');
        document.getElementById("section").scrollTop = 0;
    }

    const showAIFootnoteEditor = () => {
        setShowFootnoteEditor(true);
        const section = document.querySelector('#bottom-of-editor');
        section.scrollTop({ behavior: 'smooth', block: 'start' });
    }

    const showPromptEditor = () => {
        setShowAiPromptEditor(false);
    }

    const updateSuggested = () => {
        const updatedText = document.getElementById("ai-target-textarea").value;
        setSuggestedPara(updatedText)
    }

    const updateFootnotes = (index) => {
        const updatedText = document.getElementById("footnote_" + index).value;
        footnotes[index] = updatedText;
        setFootnotes(footnotes);
        compileFootnotes();
    }

    const getSectionName = (section) => {
        if (section == "frontMatter") {
            return "Front Matter"
        }
        if (section == "backMatter") {
            return "Back Matter"
        }
        if (section == "None") {
            return "None"
        }
        return section
    }

    useHotkeys("Control + Shift + ArrowDown", () => {
        let activeTextarea = document.activeElement.id;
        let activeId = activeTextarea.split('_')[1];
        let nextId = Number(activeId) + 1;
        activeId = "target_" + String(activeId);
        nextId = "target_" + String(nextId);
        if (document.getElementById(nextId)) {
            document.getElementById(activeId).blur();
            document.getElementById(nextId).scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            }
            );
            setTimeout(function () {
                document.getElementById(nextId).focus();
            }, 1000);
        }
    },
        { ignoredElementWhitelist: ["TEXTAREA"] });

    useHotkeys("Control + Shift + ArrowUp", () => {
        let activeTextarea = document.activeElement.id;
        let activeId = activeTextarea.split('_')[1];
        let prevId = Number(activeId) - 1;
        activeId = "target_" + String(activeId);
        prevId = "target_" + String(prevId);
        if (document.getElementById(prevId)) {
            document.getElementById(activeId).blur();
            document.getElementById(prevId).scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            }
            );
            setTimeout(function () {
                document.getElementById(prevId).focus();
            }, 1000);
        }
    },
        { ignoredElementWhitelist: ["TEXTAREA"] }
    );

    const calculateUsage = (tokensLeft, maxTokens) => {
        const usagePercentage = ((maxTokens - tokensLeft) / maxTokens) * 100;
        return Math.min(100, Math.max(0, usagePercentage)); // Ensure the value is between 0 and 100
    };

    const usageText = (tokensLeft, maxTokens) => {
        let tokensUsed = maxTokens - tokensLeft;

        return `${tokensUsed.toLocaleString()}/${maxTokens.toLocaleString()} tokens used`
    }

    const handleCloseConfirmation = () => {
        setEditConfirmation(false)
    }

    const handleOpenConfirmation = () => {
        setEditConfirmation(true)
    }

    const handleEditConfirmed = (aiWillEdit) => {
        parseText(characters, aiWillEdit)
    }



    return (
        <div className="App">
            <div ref={topRef} />
            <NavBar bookContent={book} />

            <div>


            </div>
            {/* <div className="Nav">
                <Link to="/books/contents" state={{
                    book: book
                }}
                >
                    Table of Contents
                </Link>
            </div> */}
            {/* <label htmlFor="section">Section:</label><br></br>
            <input readOnly id="section" name="section" type="text" value={section} /><br></br><br></br>
            <label htmlFor="chaptername">Element Name:</label><br></br>
            <input id="chaptername" name="chaptername" type="text" defaultValue={chaptername} /><br></br><br></br>
             */}
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '25ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField
                    InputLabelProps={{
                        style: {
                            color: 'var(--label-color)',
                        }
                    }}
                    InputProps={{
                        readOnly: true,
                        style: {
                            fontWeight: 'bold',
                            backgroundColor: 'var(--text-background-color)',
                            color: 'var(--input-text-color)',
                            borderColor: 'var(--textfield-border-color)'
                        }
                    }}
                    readonly size="small"
                    id="section"
                    label="Section"
                    variant="outlined"
                    value={getSectionName(section)} />
                <TextField
                    InputLabelProps={{
                        style: {
                            color: 'var(--label-color)',
                        }
                    }}
                    InputProps={{
                        readOnly: true,
                        style: {
                            fontWeight: 'bold',
                            backgroundColor: 'var(--text-background-color)',
                            color: 'var(--input-text-color)',
                            borderColor: 'var(--textfield-border-color)'
                        }
                    }}
                    size="small" id="chaptername" label="Element" variant="outlined" defaultValue={chaptername} />
            </Box>
            <Button startIcon={<PDFIcon />} color="secondary" style={{ margin: '25px' }} variant="contained" onClick={openDrawer}>
                Source PDF
            </Button>
            <Button startIcon={<TextIcon />} color="secondary" style={{ margin: '25px' }} variant="contained" onClick={() => handleAceOpen()}>Source Text</Button>

            <PDFViewer bookContent={book} user={uid} isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} closeDrawer={closeDrawer} pdfUrl={pdfUrl} />
            <Dialog onClose={handleCloseConfirmation} open={editConfirmation}>
                <DialogTitle>Use AI Assistance?</DialogTitle>
                <DialogContent>
                    <DialogContentText>RoboEdit will make the first round of edits for this entire chapter.</DialogContentText>
                    <br></br>
                    <DialogContentText>You will see a side by side comparison of the original vs. the edited version.</DialogContentText>
                    <br></br>
                    <Button
                        startIcon={<AutoAwesome />}
                        onClick={() => handleEditConfirmed(true)}
                        color='success'
                        loadingPosition="start"
                        variant="contained">Yes</Button>
                    <Button sx={{ marginLeft: '10px' }} onClick={() => handleEditConfirmed(false)} color='error' variant='outlined'>No</Button>
                </DialogContent>
            </Dialog>


            <Refiner book={book} aceOpen={isAceOpen} user={uid} bookTitle={book.title} clicked={handleAceClose} />

            <br></br>
            <div className="Accordions">
                <Accordion sx={{ boxShadow: 'none', border: '1px solid black', backgroundColor: 'var(--accordion-background-color)' }} defaultExpanded={paragraphs.length > 0 ? false : true} onChange={handleAccordionSourceChange('panel1')}>
                    <AccordionSummary
                        tooltipTitle="Test"
                        expandIcon={<ExpandMoreIcon style={{ color: 'var(--text-color)' }} />}
                        style={{ color: 'var(--accordion-text-color)' }}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Tooltip placement="top" title="Paste OCR-scanned text that needs editing">

                            <Stack direction="row" alignItems="center" gap={1}>
                                <ScanIcon />
                                <Typography align='center' sx={{ width: '100%', fontWeight: 'bold', flexShrink: 0 }} variant="h5" component="h5">
                                    Element Source Text</Typography>
                            </Stack>
                        </Tooltip>
                    </AccordionSummary>
                    <AccordionDetails>
                        <textarea id="source" className="SourceTextArea" placeholder="Paste chapter/element source text from OCR scan here. Paragraphs should be separated by a blank line" defaultValue={defaultSource}></textarea>
                        <br></br><br></br>
                        <Tooltip title="Separate the text out into individual paragraphs.">
                            <Button

                                color='success'
                                size='large' // Use the largest size available
                                startIcon={<AutoAwesome fontSize='inherit' />} // Match icon size with font size
                                variant="contained"
                                onClick={() => handleOpenConfirmation()}
                            >
                                Parse Text
                            </Button>
                        </Tooltip>
                        <br></br><br></br>
                        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                            {!(tokens === maxTokens) && <CircularProgress
                                color={tokens ? "primary" : "warning"}
                                sx={{
                                    width: "10px",
                                    height: "10px",
                                    borderRadius: "100%",
                                    boxShadow: "inset 0 0 0px 1px lightgray",
                                    backgroundColor: "transparent",
                                }}
                                variant="determinate"
                                value={calculateUsage(tokens, maxTokens)} />}
                        </Box>
                        <Tooltip onClick={() => window.open('https://billing.stripe.com/p/login/8wM2b3eSd9zS64w7ss', '_blank').focus()} sx={{ cursor: 'pointer' }} placement="bottom" title={tokens.toLocaleString() + " tokens remaining this month."}>
                            <Typography variant="caption" component="div" className="TokensText">
                                {usageText(tokens, maxTokens)}
                            </Typography>
                        </Tooltip>
                    </AccordionDetails>
                </Accordion>
                <br></br>
                <Accordion TransitionProps={{
                    timeout: 0
                }} sx={{ border: '1px solid black', boxShadow: 'none', backgroundColor: 'var(--accordion-background-color)' }} defaultExpanded={true} onChange={handleAccordionParaEditorChange('panel1')}>
                    <AccordionSummary
                        style={{ color: 'var(--accordion-text-color)' }}
                        expandIcon={<ExpandMoreIcon style={{ color: 'var(--text-color)' }} />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Tooltip placement="top" title="Edit each paragraph with AI assistance">
                            <Stack direction="row" alignItems="center" gap={1}>
                                <EditIcon />
                                <Typography align='center' sx={{ width: '100%', fontWeight: 'bold', flexShrink: 0 }} variant="h5" component="h5">
                                    Paragraph Editor</Typography>

                            </Stack>
                        </Tooltip>
                    </AccordionSummary>
                    <AccordionDetails>
                        {(paragraphs.length > 0) && <div id='paragraphs' className='Paragraphs'>

                            <table id="table" className="EditorTable">
                                <tbody>
                                    <tr style={{ marginBottom: '3px' }}>
                                        <Tooltip style={{ cursor: 'pointer' }} placement='top' title="The original text from your OCR scan provided above."><th style={{ color: 'var(--accordion-text-color)' }}>Source</th></Tooltip>
                                        <Tooltip style={{ cursor: 'pointer' }} placement='top' title="The text to eventually be published. Target text is what you and the AI can edit."><th style={{ color: 'var(--accordion-text-color)' }}>Target</th></Tooltip>
                                        <th></th>
                                    </tr>
                                    {generateParagraphs(paragraphs, targetParagraphs, paragraphStyles)}
                                </tbody>
                            </table>
                        </div>
                        }
                        {/* <button>New Paragraph</button><br></br> */}
                        <Button variant="contained" endIcon={<PlusIcon />} onClick={newParagraph}>New Paragraph</Button><br></br><br></br>
                        {/* <Button variant="contained" onClick={compileParagraphs} endIcon={<ReaderIcon />}>Render Proofs</Button> */}
                        {/* <Button variant="outlined" endIcon={<PlusIcon />} onClick={newFootnote}>New Footnote</Button><br></br><br></br> */}
                        {footnotes && <div id='footnotes' className='Footnotes'>
                            {generateFootnoteTextareas(footnotes)}

                        </div>
                        }
                    </AccordionDetails>
                </Accordion>
                <br></br>
            </div>

            {/* <button>Prev</button>
            <button onClick={() => newChapter()}>New</button>
            <button>Next</button><br></br><br></br> */}

            {/* <h4>Section {section}, Chapter {chapter}</h4> */}
            {/* {chaptername && <h3>{chaptername}</h3>} */}

            <div className="Accordions">

                <Accordion TransitionProps={{
                    timeout: 0
                }} sx={{ backgroundColor: 'var(--accordion-background-color)', border: '1px solid black', boxShadow: 'none' }} expanded={expandedProofs} onChange={handleAccordionProofsChange('panel1')}>
                    <AccordionSummary
                        style={{ color: 'var(--accordion-text-color)' }}
                        expandIcon={<ExpandMoreIcon style={{ color: 'var(--text-color)' }} />}
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Tooltip placement="top" title="Review and approve proofs">
                            <Stack direction="row" alignItems="center" gap={1}>
                                <CheckMarkIcon />
                                <Typography align='center' sx={{ width: '100%', fontWeight: 'bold', flexShrink: 0 }} variant="h5" component="h5">
                                    Review Proofs</Typography>
                            </Stack>
                        </Tooltip>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={"TargetParagraphs"}>
                            {generateTargetParagraphs(targetParagraphs, paragraphStyles, chaptername)}
                            {renderFootnotes(footnotes)}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="BottomBtns">
                <div className="SaveBtn">



                    {/* <div className="BtnBoxToc">
                    <Button
                        endIcon={<TocIcon />}
                        variant='contained'
                        color='info'
                        component={RouterLink}
                        to="/books/contents"
                        state={{ book: book }}
                    >
                        Table of Contents
                    </Button>
                    </div> */}

                    <div className="FabBtnBox">
                        <Tooltip title='Save Element'>
                            <Fab
                                color='success'
                                onClick={() => saveAndUpload(targetParagraphs, footnotes, paragraphStyles)}>{snackbarOpen ? <SuccessIcon /> : <SaveIcon />}</Fab>
                        </Tooltip>
                        {/* <Button color="success" variant="contained" endIcon={<SaveIcon />}
                            onClick={() => saveAndUpload(targetParagraphs, footnotes, paragraphStyles)}>Save Element</Button> */}
                    </div>
                    <div className="FabBtnBoxToc">
                        <Tooltip title='Table of Contents'>
                            <Fab
                                color='secondary'
                                component={RouterLink}
                                to="/books/contents"
                                state={{ book: book }}
                            ><TocIcon /></Fab>
                        </Tooltip>
                        {/* <Button color="success" variant="contained" endIcon={<SaveIcon />}
                            onClick={() => saveAndUpload(targetParagraphs, footnotes, paragraphStyles)}>Save Element</Button> */}
                    </div>
                </div>
                <div className="DeleteBtnEditor">
                    <div className="BtnBox">
                        <Button startIcon={<DeleteIcon />} variant="contained" onClick={() => openDeleteForm()} color="error">Delete Element</Button>
                    </div>
                    <div className="BtnBox">
                        <Button color='info' variant='contained' startIcon={<SaveAltIcon />} onClick={() => { saveDoc(targetParagraphs, paragraphStyles, chaptername) }}>Export .docx</Button>
                    </div>
                </div>
            </div>
            <br></br><br></br><br></br><br></br><br></br>


            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleSnackbarClose} severity="success">
                    Chapter Saved
                </Alert>
            </Snackbar>
            <Snackbar
                open={deleteSnackbarOpen}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                onClose={handleDeleteSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="warning">
                    Paragraph Deleted
                </Alert>
            </Snackbar>
            <Snackbar
                sx={{
                    width: 300,
                    color: 'green',
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={formatCompletedSnackbarOpen}
                autoHideDuration={4000}
                onClose={handleFormatCompletedSnackbarClose}
                action={action}
                message="Text updated."
            />

            {/* DIFF VIEWER ONLY */}

            <Dialog
                PaperProps={{
                    style: {
                        backgroundColor: 'var(--accordion-background-color)'
                    }
                }}
                open={openDiffViewer}
                fullWidth={true}
                maxWidth='xl'
                onClose={handleCloseDiffViewer}>
                <DialogTitle style={{ color: 'var(--accordion-text-color)' }}>Diff Viewer</DialogTitle>
                <DialogContent id="prompt-editor">
                    <br></br>
                    <div>
                        <ReactDiffViewer
                            useDarkTheme={prefersDarkMode}
                            hideLineNumbers
                            oldValue={targetPara}
                            compareMethod={DiffMethod.WORDS}
                            newValue={suggestedPara}
                            splitView={true}
                            showDiffOnly={false} // Add this prop
                            styles={{
                                diffContainer: {
                                    wordBreak: 'break-word',
                                },
                                contentText: {
                                    lineHeight: '17.5px!important'
                                },
                                wordDiff: {
                                    padding: '0px',
                                    display: 'inline'
                                }
                            }}
                        />
                    </div>
                    <br></br>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="warning" onClick={() => handleCloseDiffViewer()}>Close</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAIDialog} fullWidth={true} maxWidth='xl' onClose={handleCancelAiDialog}>
                <DialogTitle>AI-Suggested Edits</DialogTitle>
                <DialogContent id="prompt-editor">
                    <br></br>
                    <div>
                        <ReactDiffViewer
                            hideLineNumbers
                            oldValue={targetPara}
                            compareMethod={DiffMethod.WORDS}
                            newValue={suggestedPara}
                            splitView={true}
                            styles={{
                                diffContainer: {
                                    wordBreak: 'break-word',
                                },
                                contentText: {
                                    lineHeight: '17.5px!important'
                                },
                                wordDiff: {
                                    padding: '0px',
                                    display: 'inline'
                                }
                            }}
                        />
                    </div><br></br>
                    {
                        !showAiPromptEditor &&
                        <div id="prompt-elements">
                            <span style={{ marginLeft: '15px', marginRight: '10px' }}><b>Preset Prompts:</b></span>
                            <LoadingButton
                                className="PresetPrompt"
                                size="small"
                                variant="outlined"
                                endIcon={<DoneIcon />}
                                loading={false}
                                onClick={() => additionalPrompt("spellcheck")}>Spell Check</LoadingButton>
                            <LoadingButton
                                className="PresetPrompt"
                                size="small"
                                variant="outlined"
                                endIcon={<TitleIcon />}
                                loading={false}
                                onClick={() => additionalPrompt("title")}>Add Italics</LoadingButton>
                            {/* <LoadingButton
                                className="PresetPrompt"
                                size="small"
                                variant="outlined"
                                endIcon={<Filter1Icon />}
                                loading={false}
                                onClick={() => additionalPrompt("footnote")}>Footnote</LoadingButton> */}
                            <TextField
                                className="PromptInput"
                                autoFocus
                                placeholder="Enter additional instructions for the AI"
                                margin="dense"
                                focused
                                id="additional-prompt-textfield"
                                label="Specific Prompt"
                                type="text"
                                fullWidth
                                variant="outlined"
                            />

                            <div className="PromptButtons">
                                <LoadingButton onClick={() => additionalPrompt("specific")} variant="contained">Send Prompt</LoadingButton>
                                {
                                    !showAiPromptEditor && <>
                                        <b>&nbsp;&nbsp;&nbsp;or&nbsp;&nbsp;&nbsp;</b>
                                        <Button color="warning" onClick={() => showManualEditor()} variant="contained">Edit Manually</Button>
                                    </>
                                }
                            </div>
                        </div>
                    }

                    {showAiPromptEditor && <textarea autoFocus={true} defaultValue={suggestedPara} onChange={() => updateSuggested()}
                        className="AIPromptTextarea" id="ai-target-textarea" />}
                    {
                        showAiPromptEditor && <>
                            <b>or&nbsp;&nbsp;&nbsp;</b>
                            <Button onClick={() => showPromptEditor()} variant="contained" color="warning">Show Prompt Editor</Button>
                        </>
                    }
                    {showFootnoteEditor &&
                        <div><span><b>Footnote Editor</b></span><br></br>
                            {suggestedFootnotes.map((element, index) => {
                                return (
                                    <div>
                                        <sup>{index + 1}</sup>&nbsp; &nbsp;
                                        <textarea style={{ width: '1000px', height: '70px' }} id={"footnote-editor_" + index} defaultValue={element} />
                                    </div>
                                )
                            })}
                            <button>Add Footnote</button>
                        </div>}
                    <span id="bottom-of-editor"></span>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="warning" onClick={() => handleCancelAiDialog(aiSource)}>Cancel</Button>
                    <Button variant="outlined" color="success" onClick={() => handleAcceptAiDialog(targetParaIndex, aiSource)}>Accept</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={deleteFormOpen} onClose={handleFormClose}>
                <DialogTitle>Delete {chaptername}?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To delete this element, type in its full title below.
                    </DialogContentText><br></br>
                    <DialogContentText color='var(--text-color)'>
                        {chaptername}
                    </DialogContentText>
                    <br></br>
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <TextField
                                onChange={checkDeleteTitle}
                                autoFocus
                                margin="dense"
                                id="delete"
                                type="text"
                                fullWidth
                                helperText="Type in the full name of the element"
                                variant="standard"
                            />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button color="info" onClick={handleDeleteFormClose}>Cancel</Button>
                    <Button onClick={() => deleteElement()} disabled={deleteDisabled} color="warning" variant="contained">Delete</Button>
                </DialogActions>
            </Dialog>
            {pdfUrl && <div style={{
                width: '20px',
                position: 'fixed',
                left: '2px',
                top: '50%',
                transform: 'translateY(-50%)',
                backgroundColor: 'lightgray',
                padding: '2px', // To give some space around the icon
                borderRadius: '5px', // To make the background rounded
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Adding a shadow for better visibility
                zIndex: '1000', // Ensure the icon stays on top of other content
            }}>
                <Tooltip placement="top" title="View Source PDF">
                    <IconButton size="small" style={{ padding: '0' }} onClick={openDrawer}>
                        <SideBarIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>}
            <div>
                {/* Loader */}
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
                    <div style={{ display: 'block' }}>
                        <NotepadLoader {...notepadLoaderProps} />
                    </div>
                    <div>
                        <Typography variant="body1" color="inherit" align="center">
                            &nbsp;&nbsp;&nbsp;&nbsp;AI Proofreading...
                        </Typography>
                    </div>
                </Backdrop>
            </div>
        </div>

    );
}

export default App;
