import React, { useEffect, useRef, Fragment } from 'react';
import AceEditor from "react-ace";
import { Link, useNavigate } from 'react-router-dom';
import { Button, Grid, Typography, Tooltip, Snackbar, CircularProgress, Alert, Row, Dialog, DialogTitle, DialogContent, Chip, IconButton, DialogActions } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from "@mui/icons-material/Save"
import CheckIcon from "@mui/icons-material/Tag"
import ScanIcon from '@mui/icons-material/DocumentScannerRounded'
import CopyIcon from '@mui/icons-material/ContentCopy'
import AddIcon from '@mui/icons-material/Add'

import CloseIcon from "@mui/icons-material/Close"
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import FootnoteIcon from '@mui/icons-material/Superscript';
import { doc, setDoc } from "firebase/firestore";
import { useMediaQuery } from '@mui/material';


import './Refiner.css';
import 'ace-builds/src-noconflict/mode-plain_text';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-searchbox';

import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { db, storage, auth } from "../utils/firebase";

const Refiner = (props) => {

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const theme = prefersDarkMode ? 'monokai' : 'github';

    const user = props.user;
    const book = props.book;

    const [ocrText, setOcrText] = React.useState("")
    const [value, setValue] = React.useState(props.ocrText)
    const [highlightedLine, setHighlightedLine] = React.useState(null)
    const [chapters, setChapters] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true) // Initialize isLoading state to true
    const [snackbarOpen, setSnackbarOpen] = React.useState(false)
    const [copiedSnackbarOpen, setCopiedSnackbarOpen] = React.useState(false)

    const [isSaving, setIsSaving] = React.useState(false)
    const editorRef = useRef(null);
    const navigate = useNavigate();

    const handleCopiedSnackbarClose = () => {
        setCopiedSnackbarOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const navigateToOcrScanner = () => {
        navigate("/ocr-scanner", {
            state: {
                book: book
            },
        });
    }

    const testParse = () => {

        // Step 1: Remove all extra line breaks. Should only be one line skipped max

        const text = ocrText.replace(/\n{2,}/g, '\n\n'); // Replace 2 or more consecutive newline characters with just one

        // Step 2: Try to find gibberish text
        // Define a regular expression to match gibberish patterns


        setOcrText(text)


    }

    const onOcrChange = (newValue) => {
        // Function to handle changes in the editor content
        // This function is passed to AceEditor as the onChange prop
        // You may need to add more logic here if needed
        const tagPattern = /### (.+)/g;
        let matches;
        const ids = [];

        const chapters = [];
        const lines = newValue.split('\n');

        lines.forEach((line, index) => {
            if (line.startsWith('### ')) {
                const title = line.substring(4);
                chapters.push({ title, line: index + 1 });
            }
        });

        setChapters(chapters)
        setOcrText(newValue)
    }

    const saveText = async () => {
        setIsSaving(true);
        // Function to save the text to Firebase storage
        const storageRef = ref(storage, `${user}/${props.bookTitle}`);
        try {
            const snapshot = await uploadString(storageRef, ocrText);
            await setDoc(doc(db, "users/" + user + "/books", props.bookTitle), {
                hasOcrScanned: true,
            }, { merge: true });
            console.log('Uploaded a raw string!');
        } catch (error) {
            console.error('Error uploading text:', error);
        } finally {
            setIsSaving(false);
            setSnackbarOpen(true);
        }
    };

    const navigateToChapter = (lineNumber) => {
        // Function to navigate to a specific chapter based on line number
        const editor = editorRef.current.editor;
        if (editor) {
            editor.scrollToLine(lineNumber, true, true, function () { });
            editor.gotoLine(lineNumber, 0, true);
        }
    }

    const createChapter = () => {
        // Function to navigate to a specific chapter based on line number
        props.clicked();
    }

    const copyLinesToClipboard = (chapterLine, index, nextChapterLine) => {

        if (!nextChapterLine) {
            alert("Could not find next chapter. Copying until end of book.");
        }

        const startLine = chapterLine + 1; // The line after the chapter name or index

        const editor = editorRef.current.editor;
        if (editor) {
            // Get the document
            const session = editor.getSession();
            // Determine the endLine
            const endLine = nextChapterLine ? nextChapterLine - 1 : session.getLength(); // Copy until the end if no next chapter

            // Function to remove unwanted lines
            const removeUnwantedLines = (lines) => {
                // Remove leading and trailing blank lines
                let start = 0;
                while (start < lines.length && !lines[start].trim()) {
                    start++;
                }
                let end = lines.length - 1;
                while (end >= start && !lines[end].trim()) {
                    end--;
                }
                return lines.slice(start, end + 1).join('\n');
            };

            // Retrieve the text from the specified range
            const lines = session.getLines(startLine - 1, endLine - 1);
            const trimmedLines = removeUnwantedLines(lines);

            // Copy the text to the clipboard
            navigator.clipboard.writeText(trimmedLines).then(() => {
                console.log('Text copied to clipboard');
                setCopiedSnackbarOpen(true);

                editor.scrollToLine(startLine - 1, true, true, function () { });
            }).catch(err => {
                console.error('Could not copy text: ', err);
            });
        }
    };

    const downloadText = (type) => {

        if (type === 'ocrScanner') {
            console.log("OCR FLOW")
            console.log(props.ocrText); // Log the text content to the console

            const chapters = [];
            const lines = props.ocrText.split('\n');

            lines.forEach((line, index) => {
                if (line.startsWith('### ')) {
                    const title = line.substring(4);
                    chapters.push({ title, line: index + 1 });
                }
            });

            setChapters(chapters)
            setOcrText(props.ocrText)
            setIsLoading(false)
            return
        }


        // Function to download text from Firebase storage
        getDownloadURL(ref(storage, `${user}/${props.bookTitle}`))
            .then((url) => {
                fetch(url)
                    .then(response => {
                        if (response.ok) {
                            return response.text();
                        }
                        throw new Error('Network response was not ok.');
                    })
                    .then(text => {
                        console.log(text); // Log the text content to the console

                        const chapters = [];
                        const lines = text.split('\n');

                        lines.forEach((line, index) => {
                            if (line.startsWith('### ')) {
                                const title = line.substring(4);
                                chapters.push({ title, line: index + 1 });
                            }
                        });

                        setChapters(chapters)
                        setOcrText(text)
                        setIsLoading(false) // Set isLoading to false when text is successfully loaded
                    })
                    .catch((error) => {
                        console.error('Error fetching text:', error);
                        setIsLoading(false) // Set isLoading to false in case of error
                    });
            })
            .catch((error) => {
                console.error('Error getting download URL:', error);
                setIsLoading(false) // Set isLoading to false in case of error
            });
    }

    const handleEditorLoad = (editorInstance) => {
        // Function to handle editor load event
        // This is called when the AceEditor component is loaded
        downloadText(props.type); // Download text when editor is loaded
        editorRef.current = editorInstance; // Store reference to editor instance
        attachSelectionChangeListener(editorInstance); // Attach selection change listener
    };

    const markAsChapter = (lineNumber) => {
        // Function to mark a line as a chapter
        if (!editorRef.current) {
            console.error('Editor not initialized');
            return;
        }

        const editor = editorRef.current.editor || editorRef.current;
        const session = editor.getSession();
        const lineContent = session.getLine(lineNumber - 1);

        const prependText = "### ";
        const insertPosition = {
            row: lineNumber,
            column: 0
        };

        session.insert(insertPosition, prependText);
    };

    const addSuperscriptAtCursor = () => {
        // Function to add a superscript at the cursor position
        if (!editorRef.current) {
            console.error('Editor not initialized');
            return;
        }

        const editor = editorRef.current.editor || editorRef.current;
        const session = editor.getSession();
        const cursorPosition = editor.getCursorPosition();

        // Get user input using the prompt window
        const superscriptText = prompt("Enter the superscript number or character:");

        if (superscriptText === null) {
            console.warn('User cancelled the input');
            return;
        }

        const insertionText = `<sup>${superscriptText}</sup>`;

        session.insert(cursorPosition, insertionText);
    };

    const attachSelectionChangeListener = (editor) => {
        // Function to attach a selection change listener to the editor
        editor.getSession().selection.on('changeSelection', () => {
            const selectionRange = editor.getSelectionRange();
            const isLineSelected = selectionRange.start.row !== selectionRange.end.row ||
                (selectionRange.start.column === 0 && selectionRange.end.column === editor.getSession().getLine(selectionRange.start.row).length);

            if (isLineSelected) {
                const lineNum = selectionRange.start.row;
                setHighlightedLine(lineNum);
            }
        });
    };

    // Effect to observe changes in highlightedLine
    useEffect(() => {
        if (highlightedLine !== null) {
            console.log(`Highlighted Line: ${highlightedLine + 1}`);
        }

    }, [highlightedLine]);

    return (
        <Dialog
            maxWidth='xl'
            fullWidth
            open={props.aceOpen}
            maxHeight="100vh"
            PaperProps={{
                style: {
                    backgroundColor: 'var(--accordion-background-color)',
                    maxHeight: '90%',
                }
            }}
        >

            <DialogTitle
                style={{
                    top: 0,             // Stick it to the top of the dialog
                    zIndex: 999,
                    position: 'sticky',
                    backgroundColor: 'var(--background-color)',
                    color: 'var(--text-color)',
                }}
            >Source OCR <Chip color='warning' label={'Beta'} />
                <IconButton
                    aria-label="close"
                    onClick={props.clicked}
                    style={{
                        position: 'absolute',
                        zIndex: 1000,
                        right: 8,
                        top: 8,
                        color: 'var(--accordion-text-color)'
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <AceEditor
                        value={ocrText}
                        mode="plain_text"
                        ref={editorRef}
                        theme={theme}
                        onLoad={handleEditorLoad}
                        onChange={onOcrChange}
                        name="UNIQUE_ID_OF_DIV"
                        fontSize={14}
                        style={{ width: '100%', height: '100%' }}
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                            showLineNumbers: true,
                            showPrintMargin: false,
                            useWorker: false,
                            wrap: true,
                            enableBasicAutocompletion: true,
                        }}
                    />
                </Grid>
                <Grid item xs={4} style={{ height: '100%', overflow: 'auto' }}>
                    {ocrText && <Fragment>
                        <Typography style={{ color: 'var(--accordion-text-color)' }} variant="h6" component="h3">
                            <strong>Chapters:</strong>
                        </Typography>
                        {chapters.map((chapter, index) => (
                            <>
                                <Typography key={index} variant="body1" component="a" href="#" onClick={(e) => {
                                    e.preventDefault();
                                    navigateToChapter(chapter.line);
                                }}
                                    className={'link'}
                                    style={{ cursor: 'pointer', textDecoration: 'underline', display: 'block' }}
                                >
                                    <Tooltip placement="left" title="Copy chapter content">
                                        <IconButton
                                            style={{ color: 'var(--accordion-text-color)' }}
                                            size="small"
                                            aria-label="close"
                                            onClick={() => {
                                                const chapterLine = chapter.line;
                                                const nextChapter = chapters[index + 1];
                                                const nextChapterLine = nextChapter ? nextChapter.line : null;

                                                copyLinesToClipboard(chapterLine, index, nextChapterLine);
                                            }}
                                        ><CopyIcon fontSize="2px" /></IconButton>
                                    </Tooltip>
                                    <span style={{ margin: '10px' }}>{chapter.title}</span>
                                    {props.refinerType == "contents" && <Tooltip placement="right" title="Create new element">

                                        <IconButton
                                            size="small"
                                            aria-label="close"
                                            onClick={() => props.clickedNewChapter()}
                                        ><AddIcon fontSize="2px" /></IconButton>
                                    </Tooltip>}
                                    {/* <button 
                            style={{marginLeft: '10px'}}
                            
                            >Copy</button> */}
                                </Typography>
                            </>
                        ))
                        } </Fragment>
                    }
                    {ocrText &&
                        <>

                            <br></br><hr></hr><br></br>


                            <Button onClick={() => navigateToOcrScanner()} startIcon={<ScanIcon />} variant="contained">OCR Scanner</Button>
                            <br></br><br></br>
                            <Typography style={{ color: 'var(--accordion-text-color)' }} variant="body">
                                <b>Note:</b> Using the OCR scanner will <br></br>overwrite your existing Source OCR text.
                            </Typography><br></br><br></br>
                        </>
                    }
                    {!ocrText && <Fragment><Typography variant="h6" component="h3" style={{ color: 'var(--accordion-text-color)' }}>
                        <strong>No OCR text detected yet</strong>
                    </Typography>
                        <Typography variant="body" style={{ color: 'var(--accordion-text-color)' }}>
                            If you have a source PDF, try out RoboEdit's AI-enhanced OCR scanner:
                        </Typography><br></br><br></br>
                        <Button onClick={() => navigateToOcrScanner()} startIcon={<ScanIcon />} variant="contained">OCR Scanner</Button>
                        <br></br><br></br>
                        <Typography variant="body" style={{ color: 'var(--accordion-text-color)' }}>
                            Otherwise, you can paste your source OCR directly into the editor.
                        </Typography><br></br><br></br>
                    </Fragment>
                    }
                </Grid>

            </Grid >
            <DialogActions
                style={{
                    position: 'sticky', // Make it sticky
                    bottom: 0,          // Stick it to the bottom of the dialog
                    zIndex: 999,        // Ensure it stays on top
                    backgroundColor: 'var(--background-color)', // Match dialog background
                    borderTop: '1px solid var(--border-color)', // Optional: Add a separator
                }}
            >
                <div style={{
                    flex: '1 0 0',
                    padding: '8px', // Add padding for proper spacing
                    color: 'var(--text-color)',
                }}>
                    <Button size='small' sx={{ marginLeft: '20px', marginRight: '10px', marginTop: '5px' }} variant='outlined' color={prefersDarkMode ? 'warning' : 'info'} startIcon={<BookmarkAddIcon />} onClick={() => markAsChapter(highlightedLine)}>Mark Chapter</Button>
                    <Button size='small' sx={{ marginRight: '10px', marginTop: '5px' }} variant='outlined' color={prefersDarkMode ? 'info' : 'info'} startIcon={<FootnoteIcon />} onClick={() => addSuperscriptAtCursor(highlightedLine)}>Superscript</Button>
                    <LoadingButton loading={isSaving} sx={{ marginRight: '10px', marginTop: '5px' }} variant='contained' color='success' size='small' startIcon={<SaveIcon />} onClick={saveText}>Save</LoadingButton>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={2000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert onClose={handleSnackbarClose} severity="success">
                            OCR Text Saved
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        open={copiedSnackbarOpen}
                        autoHideDuration={3000}
                        onClose={handleCopiedSnackbarClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert onClose={handleCopiedSnackbarClose} severity="success">
                            Chapter content copied to clipboard
                        </Alert>
                    </Snackbar><br></br><br></br>
                    <Alert icon={<CheckIcon fontSize="inherit" />} severity="info">
                        <b>Tip:</b> Click on a line number to highlight it. Click the "Mark Chapter" button to designate a line as the start of a chapter.
                    </Alert>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default Refiner;
