import React, { useState, useCallback, useEffect, Fragment, useRef, useMemo } from 'react';
import {
  Button,
  Box,
  Tooltip,
  Checkbox,
  IconButton,
  DialogContentText,
  LinearProgress,
  Skeleton,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Alert,
  Fab,
  Backdrop,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Chip
} from '@mui/material';
import { animateScroll as scroll } from 'react-scroll';
import stringSimilarity from "string-similarity";
import FadeInOut from "../FadeInOut/FadeInOut";
import DifferenceIcon from '@mui/icons-material/Difference';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { LoadingButton } from '@mui/lab';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued'
import FileUpload from '../FileUploader/FileUpload';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { collection, getDocs, doc, setDoc, updateDoc, deleteDoc, getDoc, query, orderBy } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { functions, storage, db } from "../utils/firebase";
import logo from '../assets/robotedit.png';
import ttsLogo from '../assets/robotedit-tts.png';
import TocIcon from '@mui/icons-material/Toc';
import ScanIcon from '@mui/icons-material/DocumentScanner';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import useSpeechSynthesis from '../lib/hooks';
import { Filter } from 'bad-words';
import { pageTurnSounds, pcSound } from '../lib/constants/sound-effects.js';

import DeleteIcon from '@mui/icons-material/DeleteForever';
import PlayIcon from '@mui/icons-material/PlayArrow';
import CheckIcon from '@mui/icons-material/Check';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import AutoMode from '@mui/icons-material/AutoMode';
import ReadModeIcon from '@mui/icons-material/ImportContacts';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';

import { AutoAwesome, FlashOnRounded, Title } from "@mui/icons-material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { createWorker } from 'tesseract.js';
import * as pdfjsLib from 'pdfjs-dist';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookshelfIcon from '@mui/icons-material/LibraryBooks';
import SuccessIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import AutoIcon from '@mui/icons-material/AutoStories';
import WarningIcon from '@mui/icons-material/Warning';
import RightIcon from '@mui/icons-material/KeyboardArrowRight';
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import Refiner from '../Refiner/Refiner';
import PDFSpeedDial from '../PDFSpeedDial/PDFSpeedDial';
import MDEditor, { commands } from '@uiw/react-md-editor';
import useHotkeys from "@reecelucas/react-use-hotkeys";
import { deepOrange, deepPurple } from '@mui/material/colors';
import { useMediaQuery } from '@mui/material';
import { TextToSpeech } from 'tts-react';
import { SpinningCircleLoader, NotepadLoader } from 'react-loaders-kit';
import { getRandomWittyPhrase } from '../lib/constants/loader'; // Import the function


import "./OCRScanner.css";
import "./AudioPlayer.css";

pdfjsLib.GlobalWorkerOptions.workerSrc = '../../node_modules/pdfjs-dist/build/pdf.worker.min.mjs';




const OCRScanner = () => {

  const playPageTurn = () => {
    const randomIndex = Math.floor(Math.random() * 10) + 1 // Get a random index

    const randomSound = pageTurnSounds[randomIndex];  // Get the random sound URL

    console.log('Playing sound from URL:', randomSound);  // Log the URL for debugging

    const audio = new Audio(randomSound);

    // Check if the audio is correctly initialized
    audio.addEventListener('canplaythrough', () => {
      console.log('Audio can play through. Playing now...');
      audio.play().catch(error => {
        console.error('Error playing sound:', error);
      });
    });

    audio.addEventListener('error', (e) => {
      console.error('Error loading audio:', e);
    });
  };

  const playPcBoot = () => {

    const audio = new Audio(pcSound);
    audio.volume = 0.25;  // Set initial volume


    audio.addEventListener('canplaythrough', () => {
      audio.play().catch(error => {
        console.error('Error playing sound:', error);
      });
    });

    audio.addEventListener('error', (e) => {
      console.error('Error loading audio:', e);
    });

  }


  const filter = new Filter({ emptyList: true })
  filter.addWords('\u006E\u0069\u0067\u0067\u0065\u0072', '\u006E\u0069\u0067\u0067\u0065\u0072\u0073', 'darky', 'darkies');

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const user = localStorage.getItem("user");
  const navigate = useNavigate();

  const location = useLocation();
  const { book } = location.state;
  const title = book.title.toLowerCase().replace(/\s/g, '_')

  const canvasRef = React.useRef(null);

  const pdfUrl = book.pdfUrl;

  const loaderProps = {
    loading: true,
    pause: false,
    size: 35,
    colors: ['#0288D1', 'var(--background-color)']
  }

  const notepadLoaderProps = {
    loading: true,
    pause: false,
    size: 35,
    colors: ['#9F9589', '#9F9589']
  }


  const [uploadedFileUrl, setUploadedFileUrl] = useState(pdfUrl);
  const [isRendered, setIsRendered] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [openDiffViewer, setOpenDiffViewer] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTts, setIsLoadingTts] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [progress, setProgress] = useState(0)
  const [gotoPage, setGotoPage] = useState('');
  const [tokens, setTokens] = useState(0);
  const [maxTokens, setMaxTokens] = useState(0);
  const [editConfirmation, setEditConfirmation] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [scanDialogOpen, setScanDialogOpen] = useState(false);
  const [removePageHeader, setRemovePageHeader] = useState(true);
  const [identifyChapters, setIdentifyChapters] = useState(true);
  const [pageContinued, setPageContinued] = useState(true);
  const [ocrEngine, setOcrEngine] = useState('docai');
  const [showStopAutoScan, setShowStopAutoScan] = useState(false)
  const [autoScan, setAutoScan] = useState(true);
  const [editMode, setEditMode] = useState(true);
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [isVoiceReady, setIsVoiceReady] = useState(false);
  const [inputText, setInputText] = useState('');
  const [audio, setAudio] = useState(null);
  const [alignment, setAlignment] = useState(null);
  const [mp3Url, setMp3Url] = useState(null);
  const [lastWordIndex, setLastWordIndex] = useState(-1);
  const [selectedPremiumVoice, setSelectedPremiumVoice] = useState('');
  const audioRef = useRef(null);
  const speechSynthesisRef = useRef(null);
  const [currentWordIndex, setCurrentWordIndex] = useState(-1);

  const [timestamps, setTimestamps] = useState([])
  const [alertShown, setAlertShown] = useState(false);
  const [audioBase64, setAudioBase64] = useState('');
  const [savedTime, setSavedTime] = useState(0); // New state to track saved time
  const [resetAudio, setResetAudio] = useState(true);
  const [coverUrl, setCoverUrl] = useState('');
  const [ocrSettings, setOcrSettings] = useState({
    ocrEngine: 'docai',
    aiProofreadEnabled: true,
    twoColumns: false,
    maintainParagraphs: false,
    pageContinued: false,
    identifyChapters: true,
    identifySuperscripts: true,
    identifyDashes: true,
    removePageHeader: true,
    ttsEnabled: false,
    ttsEngine: 'browserDefault',
    premiumVoice: 'NFG5qt843uXKj4pFvR7C',
    soundEffectsEnabled: false,
    textSanitizer: false
  });



  const [isUploading, setIsUploading] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [textarea, setTextarea] = React.useState("");
  const [rawOcrText, setRawOcrText] = React.useState("");
  const [googleOcrText, setGoogleOcrText] = React.useState("");
  const [prevPageContent, setPrevPageContent] = React.useState("");
  const [nextPageContent, setNextPageContent] = React.useState("");
  const [fullText, setFullText] = React.useState("");
  const [isFullOcrOpen, setIsFullOcrOpen] = React.useState(false);
  const [ocrText, setOcrText] = React.useState("");
  const [images, setImages] = useState([]);
  const [textareaHeight, setTextAreaHeight] = useState('90vh'); // Initial height for the textarea
  const [scanningStatus, setScanningStatus] = useState('RoboEdit Smart OCR Scanner');
  const [ocrProgress, setOcrProgress] = React.useState("");
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isPaused, setIsPaused] = React.useState(false);
  const [audioData, setAudioData] = React.useState(null);
  const [isHidden, setIsHidden] = useState(!isPlaying);
  const [show, setShow] = useState(true);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [calibrationOffset, setCalibrationOffset] = useState(0); // New state for recalibration offset

  const textContainerRef = useRef(null);
  const isFirstLoad = useRef(true);
  const toggleShow = () => setShow(!show);

  const handleSpeechEnd = () => {
    const currentTime = new Date().toLocaleTimeString();
    console.log(`Speech has finished at ${currentTime}.`);
  };

  const { startSpeech, startElevenlabsSpeech, stopSpeech, pauseSpeech, resumeSpeech } = useSpeechSynthesis(setHighlightedIndex, selectedVoice, handleSpeechEnd);


  const uid = localStorage.getItem("user");
  const desiredWidth = 500;

  const italicize = {
    name: 'italicize',
    keyCommand: 'italicize',
    title: 'Italicize',
    buttonProps: { 'aria-label': 'Insert title3', title: 'Italicize text' }, // Added title for tooltip
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 320 612" fill="currentColor">
        <path d="M204.758 416h-33.849l62.092-320h40.725a16 16 0 0 0 15.704-12.937l6.242-32C297.599 41.184 290.034 32 279.968 32H120.235a16 16 0 0 0-15.704 12.937l-6.242 32C96.362 86.816 103.927 96 113.993 96h33.846l-62.09 320H46.278a16 16 0 0 0-15.704 12.935l-6.245 32C22.402 470.815 29.967 480 40.034 480h158.479a16 16 0 0 0 15.704-12.935l6.245-32c1.927-9.88-5.638-19.065-15.704-19.065z" fill={prefersDarkMode ? '#ffffff' : '##57A6FF'} />
      </svg>),
    execute: (state, api) => {
      let modifyText = `<i>${state.selectedText}</i>`;
      if (!state.selectedText) {
        modifyText = `<i></i>`;
      }
      api.replaceSelection(modifyText);
    },
  };

  const emDash = {
    name: 'emDash',
    keyCommand: 'emDash',
    title: 'Insert Em Dash',
    buttonProps: { 'aria-label': 'Insert em dash', title: 'Insert Em Dash' }, // Tooltip for the button
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
        <rect x="4" y="10" width="16" height="2" />
      </svg>
    ),
    execute: (state, api) => {
      let modifyText = '—'; // Em Dash
      api.replaceSelection(modifyText);
    },
  };


  const superscript = {
    name: 'superscript',
    keyCommand: 'superscript',
    title: 'Superscript',
    buttonProps: { 'aria-label': 'Insert superscript', title: 'Superscript text (for footnotes)' }, // Tooltip for the button
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
        <text x="2" y="20" fontFamily="Arial, sans-serif" fontSize="16" fontWeight="bold">x</text>
        <text x="12" y="14" fontFamily="Arial, sans-serif" fontSize="10" fontWeight="bold">2</text>
      </svg>
    ),
    execute: async (state, api) => {
      let modifyText;

      if (state.selectedText) {
        // If text is selected, wrap it in <sup></sup>
        modifyText = `<sup>${state.selectedText}</sup>`;
      } else {
        // If no text is selected, prompt the user to enter a number
        const num = await prompt("Enter the superscript value:");
        if (num !== null) {
          modifyText = `<sup>${num}</sup>`;
        }
      }

      if (modifyText) {
        api.replaceSelection(modifyText);
      }
    },
  };

  const bold = {
    name: 'bold',
    keyCommand: 'bold',
    title: 'bold',
    buttonProps: { 'aria-label': 'Bold text', title: 'Bold text' }, // Added title for tooltip
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 20 20" fill="currentColor">
        <path d="M9.93 3a9.34 9.34 0 0 1 2.39.27 4.53 4.53 0 0 1 1.62.73 2.87 2.87 0 0 1 .91 1.18 4 4 0 0 1 .29 1.55 3.09 3.09 0 0 1-.14.93 2.77 2.77 0 0 1-.43.83 3.21 3.21 0 0 1-.75.71 4.56 4.56 0 0 1-1.09.54 4 4 0 0 1 2.1 1.1 2.86 2.86 0 0 1 .68 2 4 4 0 0 1-.34 1.65 3.73 3.73 0 0 1-1 1.32 4.66 4.66 0 0 1-1.6.87 7 7 0 0 1-2.19.31H5V3zM7.87 5.2V9h1.89a5.1 5.1 0 0 0 1.07-.1 2.13 2.13 0 0 0 .78-.32A1.44 1.44 0 0 0 12.1 8a2.07 2.07 0 0 0 .17-.87 2.51 2.51 0 0 0-.14-.89 1.31 1.31 0 0 0-.43-.59 1.86 1.86 0 0 0-.7-.35 4.72 4.72 0 0 0-1-.1zm2.46 9.58a3.24 3.24 0 0 0 1.13-.17 1.91 1.91 0 0 0 .71-.45 1.54 1.54 0 0 0 .37-.64 2.66 2.66 0 0 0 .11-.75 2.2 2.2 0 0 0-.12-.76 1.36 1.36 0 0 0-.4-.57 1.89 1.89 0 0 0-.72-.36 4.09 4.09 0 0 0-1.1-.13H7.87v3.83z" fill={prefersDarkMode ? '#ffffff' : '##57A6FF'} />
      </svg>),
    execute: (state, api) => {
      let modifyText = `<b>${state.selectedText}</b>`;
      if (!state.selectedText) {
        modifyText = `<b></b>`;
      }
      api.replaceSelection(modifyText);
    },
  };


  const title3 = {
    name: 'title3',
    keyCommand: 'title3',
    title: 'Mark chapter',
    buttonProps: { 'aria-label': 'Insert title3', title: 'Mark chapter' }, // Added title for tooltip
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
        <path fill="currentColor" d="M17,3H7C5.9,3 5,3.9 5,5V21L12,18L19,21V5C19,3.9 18.1,3 17,3Z" />
      </svg>
    ),
    execute: (state, api) => {
      let modifyText = `### ${state.selectedText}\n`;
      if (!state.selectedText) {
        modifyText = `### `;
      }
      api.replaceSelection(modifyText);
    },
  };

  const splitParas = (rawOcrText) => {
    // Split the text into lines
    const lines = rawOcrText.split('\n');

    let paragraphs = [];
    let currentParagraph = [];

    for (const line of lines) {
      if (line.trim() === '') {
        // Empty line indicates paragraph break
        if (currentParagraph.length > 0) {
          paragraphs.push(currentParagraph.join(' '));
          currentParagraph = [];
        }
      } else {
        currentParagraph.push(line.trim());
      }
    }

    // Add the last paragraph if it exists
    if (currentParagraph.length > 0) {
      paragraphs.push(currentParagraph.join(' '));
    }

    // Join paragraphs with double newlines
    return paragraphs.join('\n\n');
  }

  const textContinued = {
    name: 'textContinued',
    keyCommand: 'textContinued',
    title: 'Text Continued from Previous Page',
    buttonProps: { 'aria-label': 'Insert title4', title: 'Text Continued from Previous Page' }, // Added title for tooltip
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20">
        <text fill='var(--text-color)' x="0" y="15" fontFamily="Arial" fontSize="14">[...]</text>
      </svg>
    ),
    execute: (state, api) => {
      let modifyText = `[...] ${state.selectedText}\n`;
      if (!state.selectedText) {
        modifyText = `[...] `;
      }
      api.replaceSelection(modifyText);
    },
  };

  const warpedText = {
    name: 'warpedText',
    keyCommand: 'warpedText',
    title: 'Warped Text',
    buttonProps: { 'aria-label': 'Insert title3', title: 'Mark Warped Text' }, // Added title for tooltip
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
      </svg>
    ),
    execute: (state, api) => {
      api.replaceSelection(`<<<Warped Text>>>`);
    },
  };

  const standardizeLineBreaks = {
    name: 'standardizeLineBreaks',
    keyCommand: 'standardizeLineBreaks',
    title: 'Standardize line breaks for selection',
    buttonProps: { 'aria-label': 'Standardize Line Breaks', title: 'Standardize line breaks for selection' }, // Tooltip for the button
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
        <path fill="currentColor" d="M4 7h16v2H4V7zm0 10h16v2H4v-2z" />
      </svg>
    ),
    execute: (state, api) => {
      // Get the current selection text from the editor
      const fullText = state.text;

      // Get the selected text
      const selectedText = fullText.substring(state.selection.start, state.selection.end);

      // Remove single line breaks by replacing them with a space, leaving double line breaks unchanged
      const standardizedText = selectedText.replace(/([^\n])\n(?!\n)/g, '$1 ').trim();

      // Replace the selected text in the editor with the adjusted text
      api.replaceSelection(standardizedText);
    },
  };





  // async function processFile(file) {
  //     const worker = await createWorker('eng');
  //     setTextarea("")
  //     // fullDocumentSection.style.display = 'none';
  //     // imageContainer.innerHTML = '';
  //     // const originalText = dropzone.innerText;
  //     // dropzone.innerText = 'Processing file...';
  //     // dropzone.classList.add('disabled');
  //     // fileSelectionAllowed = false;

  //     if (file.type === 'application/pdf') {
  //       const { numPages, imageIterator } = await convertPDFToImages(file);
  //       let done = 0;
  //     //   dropzone.innerText = `Processing ${numPages} page${numPages > 1 ? 's' : ''}`;
  //       for await (const { imageURL } of imageIterator) {
  //         // const ta = await displayImage(imageURL);
  //     //     const { text } = await ocrImage(worker, imageURL);

  //     //     try {
  //     //         // Send request to OpenAI API
  //     //     const response = await openai.createChatCompletion({
  //     //         model: "gpt-3.5-turbo",
  //     //         temperature: 0.5,
  //     //         max_tokens: 4000,
  //     //         frequency_penalty: 0,
  //     //         presence_penalty: 0,
  //     //         top_p: 1,
  //     //         messages: [
  //     //             { role: "system", content: 'You are a helpful assistant preparing an OCR-scanned manuscript for publication. The text may contain scannos and archaic spellings. Spell check the following paragraph from a novel. Do not change more than absolutely necessary to make the text readable in terms of spelling. Remove line breaks and join hyphenations.' },
  //     //             { role: "user", content: text }
  //     //             ]
  //     //     }, { timeout: 60000 });

  //     //     // Handle response
  //     //     const proofreadText = response.data.choices[0].message.content;
  //     //     const tokensUsed = response.data.usage.total_tokens;

  //     //     setRawOcrText(text)
  //     //     setTextarea(proofreadText);
  //     //     done += 1;
  //     //     // dropzone.innerText = `Done ${done} of ${numPages}`;
  //     //   }
  //     //   catch (error) {
  //     //     alert(error)
  //     //   }
  //     }
  //     } else {
  //       alert("OCR scannng is only available for PDFs at this time")
  //     }
  // }

  async function ocrImage(worker, imageUrl) {
    const {
      data: { text },
    } = await worker.recognize(imageUrl);
    return { text };
  }

  async function convertPDFToImages(file) {
    // returns { numPages, imageIterator }
    const pdfjsLib = await import('pdfjs-dist')
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      "https://cdn.jsdelivr.net/npm/pdfjs-dist@4.2.67/build/pdf.worker.min.mjs";
    const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
    const numPages = pdf.numPages;
    async function* images() {
      for (let i = 1; i <= numPages; i++) {
        try {
          const page = await pdf.getPage(i);
          const viewport = page.getViewport({ scale: 1 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.width = desiredWidth;
          canvas.height = (desiredWidth / viewport.width) * viewport.height;
          const renderContext = {
            canvasContext: context,
            viewport: page.getViewport({ scale: desiredWidth / viewport.width }),
          };
          await page.render(renderContext).promise;
          const imageURL = canvas.toDataURL('image/jpeg', 0.8);
          yield { imageURL };
        } catch (error) {
          console.error(`Error rendering page ${i}:`, error);
        }
      }
    }
    console.log("RAN", numPages)
    return { numPages: numPages, imageIterator: images() };
  }


  const handleFullOcrClose = () => {
    setIsFullOcrOpen(false)
  }

  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  const handleSettingsClose = () => {
    setSettingsOpen(false)
  }

  const handleSettingsOpen = () => {
    setSettingsOpen(true)
  }

  const handleStopAutoScan = () => {
    window.location.reload()
  }

  const handleStartAutoScan = () => {
    setShowStopAutoScan(true);
    handleAutoScan(currentPage);
  };

  const goToPreviousPage = async (currentPage) => {
    console.log(currentPage)
    stopSpeech()
    handleStopSpeech()
    setEditMode(true)
    if (currentPage <= 1) {
      console.error("There is no previous page");
      return; // Exit the function if there is no previous page
    }
    if (googleOcrText) {
      savePage(currentPage, googleOcrText)
      localStorage.setItem(`${title}_${currentPage}`, googleOcrText);
    }
    const previousPage = prevPage => Math.max(prevPage - 1, 1);
    setGoogleOcrText("")
    setAudioData(null)
    setRawOcrText("")
    if (ocrSettings.soundEffectsEnabled) {
      ocrSettings.soundEffectsEnabled && playPageTurn();
    }
    loadPage(previousPage(currentPage).toString());
    const p = Math.round((currentPage / numPages) * 100);
    setProgress(p)
    setCurrentPage(previousPage);
  };


  const handleCloseDiffViewer = () => {
    setOpenDiffViewer(false)
  }

  const handleOpenDiffViewer = () => {
    console.log("clicked")
    // handleFormatCompletedSnackbarClose();
    setOpenDiffViewer(true)
  }

  const stopAutoScan = () => {
    setAutoScan(false)
  }


  const handleVoiceChange = (event) => {

    const selectedVoiceId = event.target.value;
    setSelectedPremiumVoice(selectedVoiceId);
    console.log('Selected Voice ID:', selectedVoiceId);
    // You can now use the selectedVoiceId for further actions, such as making API calls
    handleOcrSettingChange('premiumVoice', event.target.value)
  };


  const handleUpload = useCallback(async (file) => {
    try {
      setIsUploading(true)
      const storage = getStorage();

      let fileName = book.title.toLowerCase().replace(/\s/g, '_') + '.pdf'

      const storageRef = ref(storage, user + '/' + fileName);

      // Upload the file to Cloud Storage
      const snapshot = await uploadBytes(storageRef, file);

      console.log('Uploaded a blob or file and called the Cloud Function!');

      const downloadURL = await getDownloadURL(storageRef);
      await setDoc(doc(db, "users", user, "books", book.title), {
        sourcePdfUrl: downloadURL
      }, { merge: true }).then(() => {
        book.pdfUrl = downloadURL;
        setUploadedFileUrl(downloadURL);
        setIsUploading(false);
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsUploading(false)
      alert("There was a problem uploading the file: " + error)
    }
  }, [storage]);



  const replaceSourcePDF = async () => {
    if (window.confirm("Are you sure you want to delete your source PDF? This action cannot be undone and will throw off your page progress.")) {

      try {
        setIsDeleting(true)
        // Get the storage reference from the download URL
        const storage = getStorage();
        let fileName = book.title.toLowerCase().replace(/\s/g, '_') + '.pdf'
        const fileRef = ref(storage, user + '/' + fileName);

        // Delete the file from storage
        await deleteObject(fileRef);

        await setDoc(doc(db, "users", user, "books", book.title), {
          sourcePdfUrl: ""
        }, { merge: true }).then(() => {
          book.pdfUrl = "";
          if (location.state) {
            location.state.pdfUrl = "";
          }
          setUploadedFileUrl("");
          setIsDeleting(false)
        });

        console.log('File deleted successfully');
      } catch (error) {
        setIsDeleting(false)
        console.error('Error deleting file:', error);
        alert('Error deleting file:', error);
      }
    }
  }

  const handleActionClick = (actionName) => {
    // You can handle different actions based on the name
    if (actionName === 'Delete Source PDF') {
      // Perform delete logic here
      replaceSourcePDF()
    }
  };

  const goToNextPage = async () => {
    handleStopSpeech();
    stopAudio();
    setEditMode(true);
    if (currentPage >= numPages) {
      console.error("There is no next page");
      return;
    }

    if (googleOcrText) {
      // Check if the text is all blanks
      const trimmedText = googleOcrText.trim();  // Remove whitespace from both ends

      if (trimmedText === '') {
        // If the text is all blanks, set to an empty string
        await savePage(currentPage, '');
        localStorage.setItem(`${title}_${currentPage}`, '');
      } else {
        localStorage.setItem(`${title}_${currentPage}`, googleOcrText);
        await savePage(currentPage, googleOcrText);
      }
    }

    setGoogleOcrText("");
    setRawOcrText("");
    if (ocrSettings.soundEffectsEnabled) {
      playPageTurn();
    }

    // Update the current page within the setCurrentPage updater function to prevent race conditions
    setCurrentPage((prevPage) => {
      const nextPage = Math.min(prevPage + 1, numPages);
      loadPage(nextPage.toString()); // Load the new page
      return nextPage;
    });

    const p = Math.round(((currentPage + 1) / numPages) * 100); // Adjust progress for next page
    setProgress(p);

    console.log(`Moved to next page`);
  };


  const loadPage = async (page) => {
    const docRef = doc(db, "users", user, "books", book.title, "ocrscan", page);
    const docSnap = await getDoc(docRef);
    setResetAudio(true);
    if (docSnap.exists()) {
      console.log("load page from firebase")
      setGoogleOcrText(docSnap.data().content)
      if (docSnap.data().rawContent) {
        setRawOcrText(docSnap.data().rawContent)
      }
      if (docSnap.data().audioData) {
        setAudioData(docSnap.data().audioData)
      } else {
        setAudioData(null)
      }
    } else {
      console.log("No such document!");
    }
  }

  useHotkeys("Control + Alt + ArrowRight", () => {
    goToNextPage()
  },
    { ignoredElementWhitelist: ["TEXTAREA"] }
  );

  useHotkeys("Control + Alt + ArrowLeft", () => {
    goToPreviousPage(currentPage)
  },
    { ignoredElementWhitelist: ["TEXTAREA"] }
  );

  useHotkeys("Control + Shift + S", () => {
    googleVision()
  },
    { ignoredElementWhitelist: ["TEXTAREA"] }
  );

  const checkAndRemoveContinuation = (prevPageContent, currentPageContent) => {
    // Helper function to split a paragraph into sentences
    const splitIntoSentences = (text) => {
      return text.match(/[^.!?]*[.!?]/g) || []; // Matches sentences ending with ., !, or ?
    };

    const splitIntoParagraphs = (text) => {
      return text.split(/\n\s*\n/).filter(Boolean); // Matches paragraphs separated by one or more blank lines
    };

    // Split the previous page's content into paragraphs
    const prevParagraphs = prevPageContent.trim().split("\n").filter(Boolean);
    const lastPrevParagraph = prevParagraphs[prevParagraphs.length - 1];

    // Split the last paragraph into sentences
    const lastPrevSentences = splitIntoSentences(lastPrevParagraph);

    console.log(lastPrevSentences)

    // Split the current page's content into sentences
    const firstCurrentSentences = splitIntoSentences(currentPageContent);
    const firstCurrentSentence = firstCurrentSentences[0].replace(/([^\n])\n(?!\n)/g, '$1 ').trim(); // Get the first sentence

    console.log(firstCurrentSentence)

    if (!firstCurrentSentence) {
      // If there's no first sentence in the current content, return the original content
      return currentPageContent;
    }

    const isContinuation = lastPrevSentences.some((prevSentence) => {
      const trimmedPrev = prevSentence.trim();
      const trimmedCurrent = firstCurrentSentence.trim();

      // Check if the current sentence is a substring of the previous sentence
      if (trimmedPrev.includes(trimmedCurrent) || trimmedCurrent.includes(trimmedPrev)) {
        return true;
      }

      // Fallback to similarity check for slightly different sentences
      const similarity = stringSimilarity.compareTwoStrings(trimmedPrev, trimmedCurrent);
      return similarity >= 0.9; // 90% similarity
    });

    // If a continuation is detected, remove the first sentence from the current page's content
    if (isContinuation) {
      const paragraphs = splitIntoParagraphs(currentPageContent);
      paragraphs.shift(); // Remove the first sentence
      return paragraphs.join("\n\n"); // Reassemble the content with two line breaks between paragraphs
    }

    return currentPageContent; // Return original content if no continuation is detected
  };


  const handleJumpToPage = async (e, page, numpages) => {
    setGoogleOcrText('')
    setRawOcrText('')

    if (e && e.preventDefault) {
      e.preventDefault(); // Prevent the form from causing a page reload
    }

    const pages = numPages || numpages;



    const pageNumber = page ? parseInt(page, 10) : parseInt(gotoPage, 10);
    const pageNumberStr = pageNumber.toString()
    if (pageNumber > 0 && pageNumber <= pages) {
      setCurrentPage(pageNumber);
      setGotoPage(''); // Clear the input after jumping
      loadPage(pageNumberStr)
      setGotoPage("")
      const p = Math.round((pageNumber / pages) * 100);
      setProgress(p)

      await setDoc(doc(db, "users", user, "books", book.title), {
        ocrProgress: pageNumberStr
      }, { merge: true })

    } else {
      alert('Invalid page number.');
      document.getElementById('jump-page').focus();
    }
  };

  const googleVision = async () => {
    try {
      // Step 1: Scan page 1
      handleStopSpeech();
      setScanningStatus("Scanning page 1...");
      setGoogleOcrText(""); // Clear the previous OCR text
      const staticLogo = document.getElementById('logoStatic');
      const animatedLogo = document.getElementById('logoAnimated');
      staticLogo.style.display = "none";
      animatedLogo.style.display = "block";
      setIsLoading(true);

      const processImageWithDocumentAI = httpsCallable(functions, 'processImageWithDocumentAI');

      const canvasPage1 = canvasRef.current;
      const imageDataURLPage1 = canvasPage1.toDataURL('image/png');
      const base64ImagePage1 = imageDataURLPage1.split(',')[1];
      let isPageContinued = false

      if (ocrSettings.maintainParagraphs) {
        try {
          const processImageWithOpenAI = httpsCallable(functions, "processImageWithOpenAI");

          const paraDetection = await processImageWithOpenAI({ base64Image: base64ImagePage1 });
          isPageContinued = paraDetection
        } catch (err) {
          console.error("Error calling the function:", err);
        }

      }


      // OCR for page 1
      const resultPage1 = await processImageWithDocumentAI({
        imageBase64: base64ImagePage1,
        useTwoColumn: ocrSettings.twoColumns || false,
      });

      let textPage1 = resultPage1.data.text;

      console.log('textpage1', textPage1)

      const pageInt = Number(currentPage);
      const pageStr = currentPage.toString();

      const prevPageInt = pageInt - 1;
      const prevpageStr = prevPageInt.toString();

      if (ocrSettings.maintainParagraphs && localStorage.getItem(`${title}_${prevpageStr}`)) {
        const prevPageContent = localStorage.getItem(`${title}_${prevpageStr}`);
        console.log('prevcontent', prevPageContent)
        const updatedTextPage1 = checkAndRemoveContinuation(prevPageContent, textPage1);

        if (updatedTextPage1 !== textPage1) {
          console.log("Continuation detected. Updated the current page content.");
        } else {
          console.log("No continuation detected.");
        }

        // Use updatedTextPage1 for further processing
        textPage1 = updatedTextPage1;
      }

      setRawOcrText(textPage1); // Set OCR text for page 1
      console.log('OCR Text Page 1:', textPage1);


      await setDoc(doc(db, "users", user, "books", book.title, "ocrscan", pageStr), {
        rawContent: textPage1,
        page: pageInt
      }, { merge: true })

      // Check if maintainParagraphs is true
      let finalTextForPage1 = textPage1;

      if (ocrSettings.maintainParagraphs) {
        // Step 2: Move to page 2 and scan if maintainParagraphs is enabled
        goToNextPage(); // Navigate to page 2
        console.log('Waiting 5 seconds before scanning page 2');
        await pageTurnDelay(5000);
        setScanningStatus("Scanning page 2...");

        const canvasPage2 = canvasRef.current;
        const imageDataURLPage2 = canvasPage2.toDataURL('image/png');
        const base64ImagePage2 = imageDataURLPage2.split(',')[1];

        // OCR for page 2
        const resultPage2 = await processImageWithDocumentAI({
          imageBase64: base64ImagePage2,
          useTwoColumn: ocrSettings.twoColumns || false,
        });

        const textPage2 = resultPage2.data.text;
        console.log('OCR Text Page 2:', textPage2);

        // Step 3: Perform continuation and merge text
        if (isPageContinued) {
          const continuationText = checkForContinuedParagraph(textPage1, textPage2);

          // Ensure no duplicate content is appended to finalTextForPage1
          finalTextForPage1 = `${finalTextForPage1.trim()} ${continuationText.trim()}`.trim();
        }
        console.log('Final OCR Text for Page 1 with continuation:', finalTextForPage1);

        // Go back to page 1
        await goToPreviousPage((pageInt + 1).toString);
        setRawOcrText("");
      }

      if (!ocrSettings.aiProofreadEnabled) {
        await delay(5000);
      }


      // Step 4: Post-process the final text
      finalTextForPage1 = postProcessText(finalTextForPage1); // Post-process to remove unwanted content like '###147'
      console.log('finalText googleOcr', finalTextForPage1)
      setGoogleOcrText(finalTextForPage1);
      console.log("Trying to override page 1...",)

      let results = finalTextForPage1


      if (ocrSettings.aiProofreadEnabled) {

        results = await aiProofread(results);

        console.log('aiproofreadresults', results)

      } else {
        staticLogo.style.display = "block";
        animatedLogo.style.display = "none";
        setIsLoading(false);
        setScanningStatus("RoboEdit Smart OCR Scanner");
      }

      // No need for .then() here, since results are already resolved
      setRawOcrText(textPage1); // This will happen after the proofreading is complete

      return results;

    } catch (error) {
      console.error('Failed to process image:', error);
      setScanningStatus("RoboEdit Smart OCR Scanner");
    } finally {
      setScanningStatus("RoboEdit Smart OCR Scanner");
      const staticLogo = document.getElementById('logoStatic');
      const animatedLogo = document.getElementById('logoAnimated');
      staticLogo.style.display = "block";
      animatedLogo.style.display = "none";
    }
  };

  const pageTurnDelay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


  // Helper function to remove '###' followed by numbers, handling potential line breaks and spaces
  const postProcessText = (text) => {
    // Remove single line breaks, leave double line breaks
    if (!ocrSettings.aiProofreadEnabled) {
      return text.replace(/([^\n])\n(?!\n)/g, '$1 ').trim();
    }

    return text.replace(/\s*###\d+\s*/g, ''); // Remove '###' followed by numbers, allowing for spaces or line breaks
  };

  // Helper function to check for paragraph continuation and merge accordingly
  const checkForContinuedParagraph = (textPage1, textPage2) => {
    // Split texts into paragraphs
    const paragraphsPage1 = textPage1.split('\n\n');
    const paragraphsPage2 = textPage2.split('\n\n');

    // Get the last paragraph from page 1
    const lastParagraphPage1 = paragraphsPage1.pop().trim();

    // Get the first paragraph from page 2
    const firstParagraphPage2 = paragraphsPage2.shift().trim();

    // Extract the last word from the last paragraph of page 1
    const lastWordPage1 = lastParagraphPage1.split(' ').pop().trim();
    const firstWordPage2 = firstParagraphPage2.split(' ')[0].trim();

    // Check if the last word of page 1 is incomplete (broken word scenario)
    if (!lastWordPage1.endsWith('.') && firstWordPage2.startsWith(lastWordPage1)) {
      // Extract the continuation from page 2
      const restOfWord = firstWordPage2.slice(lastWordPage1.length);
      const remainingTextPage2 = firstParagraphPage2.slice(firstWordPage2.length).trim();

      // Merge the last paragraph of page 1 with the continuation from page 2
      return `${lastParagraphPage1}${restOfWord} ${remainingTextPage2}`;
    }

    // If the last paragraph is complete, append only the unique part of page 2
    return firstParagraphPage2;
  };

  const tesseractOCR = async () => {
    setScanningStatus("Scanning page...");
    const staticLogo = document.getElementById('logoStatic');
    const animatedLogo = document.getElementById('logoAnimated');
    staticLogo.style.display = "none";
    animatedLogo.style.display = "block";
    setGoogleOcrText("");
    setIsLoading(true);
    console.log('Starting Tesseract OCR');

    const canvas = canvasRef.current;
    const imageDataURL = canvas.toDataURL('image/png');

    try {
      const worker = await createWorker();
      await worker.loadLanguage('eng');
      await worker.initialize('eng');

      const { data: { text } } = await worker.recognize(imageDataURL);
      console.log('OCR Text:', text);
      setRawOcrText(text);

      await worker.terminate();

      const results = await aiProofread(text);
      return results;
    } catch (error) {
      console.error('Failed to process image:', error);
      setScanningStatus("RoboEdit Smart OCR Scanner");
    } finally {
      setScanningStatus("RoboEdit Smart OCR Scanner");
      staticLogo.style.display = "block";
      animatedLogo.style.display = "none";
      setIsLoading(false);
    }
  };

  const savePage = async (page, googleOcrText) => {
    console.log(googleOcrText);
    console.log('raw', rawOcrText)
    const audioDataObj = audioData;
    const pageInt = Number(page);
    const pageStr = page.toString();
    const nextPage = Math.min(page + 1, numPages).toString(); // Calculate the next page, ensuring it doesn't exceed numPages

    if (rawOcrText) {
      await setDoc(doc(db, "users", user, "books", book.title, "ocrscan", pageStr), {
        content: googleOcrText,
        rawContent: rawOcrText,
        page: pageInt,
        audioData: audioDataObj
      }, { merge: true }).then(
        await setDoc(doc(db, "users", user, "books", book.title), {
          ocrProgress: nextPage,
        }, { merge: true }).then(
          console.log("Saved page progress")
        )
      )
    }

    else {
      await setDoc(doc(db, "users", user, "books", book.title, "ocrscan", pageStr), {
        content: googleOcrText,
        audioData: audioDataObj,
        page: pageInt,
      }, { merge: true }).then(
        await setDoc(doc(db, "users", user, "books", book.title), {
          ocrProgress: nextPage,
        }, { merge: true }).then(
          console.log("Saved page progress")
        )
      )
    }
  }

  const handleOpenConfirmation = () => {
    setEditConfirmation(true)
  }


  const handleOpenAlert = () => {
    setAlertOpen(true)
  }

  const combineDocs = async () => {

    setAlertOpen(false)


    const dbPath = `users/${uid}/books/${book.title}/ocrscan`;
    const collRef = collection(db, dbPath);
    const q = query(collRef, orderBy('page', 'asc'));  // Add orderBy clause for sorting by 'page' field in ascending order
    console.log('collref', q)
    try {
      const querySnapshot = await getDocs(q);
      let combinedContent = '';

      querySnapshot.forEach(doc => {
        const data = doc.data();
        console.log('query', data.content)
        console.log('pageorder', data.page)
        if (data.content) {
          combinedContent += data.content + "\n";  // Append each document's content followed by a newline
        }
      });

      console.log("Combined Content:", combinedContent);

      combinedContent = combinedContent.replace(/\n\[.*?\]/g, match => match.trim()); // Remove the newline before '[...]' and trim spaces
      combinedContent = combinedContent.replace(/\[\.\.\.\]/g, ' ');
      combinedContent = combinedContent.replace(/\n(###)/g, '\n\n$1');

      setOcrText(combinedContent);
      setIsFullOcrOpen(true);
    } catch (error) {
      console.error("Error combining documents:", error);
      alert(error)
    }
  }

  const calculateUsage = (tokensLeft, maxTokens) => {
    const usagePercentage = ((maxTokens - tokensLeft) / maxTokens) * 100;
    return Math.min(100, Math.max(0, usagePercentage)); // Ensure the value is between 0 and 100
  };

  const usageText = (tokensLeft, maxTokens) => {
    let tokensUsed = maxTokens - tokensLeft;

    return `${tokensUsed.toLocaleString()} / ${maxTokens.toLocaleString()} tokens used`
  }

  const getTokenCount = async () => {

    //TODO: TOKENS LOGIC
    let tokens, maxTokens, status

    const docRef = doc(db, "users/" + uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      tokens = docSnap.data().tokens;
      maxTokens = docSnap.data().maxTokens;
      status = docSnap.data().status || "";
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }

    setTokens(tokens)
    setMaxTokens(maxTokens)



    return Number(tokens);

  }

  const handleTokenCheck = async () => {
    let tokens, maxTokens, status;

    const docRef = doc(db, "users/" + uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      tokens = docSnap.data().tokens;
      maxTokens = docSnap.data().maxTokens;
      status = docSnap.data().status || "";

      if (tokens <= 0) {
        if (status === 'active') {
          // Assuming window.location.href should be assigned, not called as a function
          window.location.href = 'https://billing.stripe.com/p/login/8wM2b3eSd9zS64w7ss';
        } else {
          navigate("/subscribe");
        }
      }
    } else {
      console.log("No such document!");
    }

    setTokens(tokens);
    setMaxTokens(maxTokens);

    return Number(tokens);
  }

  const updateTokenCount = async (tokensUsed) => {

    let tokens = await getTokenCount();

    console.log("TOTESTOKENS", tokens)

    tokens = tokens - tokensUsed;

    console.log("CALCTOKENS", tokens)


    if (tokens <= 0) {
      setTokens(0)
      await setDoc(doc(db, "users/" + uid), { tokens: 0 }, { merge: true });
      return false
    } else {
      setTokens(tokens)
      await setDoc(doc(db, "users/" + uid), { tokens: tokens ? tokens : 0 }, { merge: true });
      return true
    }
  }

  // Function to convert data URL to Blob
  const dataURLToBlob = (dataURL) => {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  };

  // const updateTextAreaHeight = () => {
  //   var canvasHeight = document.getElementById('canvas').height;
  //   document.getElementById('md-edtior').style.height = canvasHeight + 'px';
  // }

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));


  const aiProofread = async (text) => {
    setScanningStatus("AI proofreading...");
    const staticLogo = document.getElementById('logoStatic');
    const animatedLogo = document.getElementById('logoAnimated');

    console.log("OPENAITEXTTOSEND", text);

    let tokens = await handleTokenCheck();
    if (!tokens) return;

    try {

      let promptInstructions = [
        "1. Correct spelling errors to improve readability, but preserve archaic spellings and do not change the text more than necessary.",
      ];

      if (ocrSettings.removePageHeader) {
        promptInstructions.push("2. Totally remove any text that appears to be a page header, typically found at the top of the page and often in all caps. This includes things like the page number, the book title, and the author's name.");
      }

      promptInstructions.push("3. Remove all line breaks within paragraphs.");
      promptInstructions.push("4. Merge words that were incorrectly hyphenated due to line breaks, but maintain correct hyphenation within words.");

      if (ocrSettings.identifyChapters) {
        promptInstructions.push("5. Identify what could be chapter or part names (this could be words or numbers like I, II, Chapter 1, Chapter 2) and prefix them with '###'.");
      }

      promptInstructions.push(`6. This is page ${currentPage} of ${numPages}. This is from a PDF, so numbering might be slightly off. Delete any numbers that appear to be page numbers.`);

      if (ocrSettings.pageContinued) {
        promptInstructions.push("7. If the beginning of the text appears to be a continuation from a previous page, insert '[...] ' at the beginning of the text.");
      }

      if (ocrSettings.identifySuperscripts) {
        promptInstructions.push("8. If anything looks like it could be a superscript, wrap it in a <sup> tag");
      }

      if (ocrSettings.identifyDashes) {
        promptInstructions.push("9. Replace dashes or hyphens with em dashes where appropriate");
      }

      // if (ocrSettings.twoColumns) {
      //   promptInstructions.push("10. This text was scanned from a two-column layout. Some paragraphs might be out of order. Try to put them in the correct order");
      // }

      let prompt = `You are an AI assistant tasked with preparing an OCR-scanned manuscript titled ${book.title} for publication. Follow these instructions:
    
        ${promptInstructions.join("\n")}
        
        The text may contain scanning errors ('scannos'). Correct these while preserving the original meaning and style of the text as much as possible. 
        
        Your response should maintain paragraphs and be structured as follows:
        <corrected_text>
        [The corrected text goes here, with no introductory phrases.]
        </corrected_text>
        
        Text to proofread:
        `;

      if (book.author) {
        prompt = `You are an AI assistant tasked with preparing an OCR-scanned manuscript titled ${book.title} by ${book.author} for publication. Follow these instructions:
    
        ${promptInstructions.join("\n")}
        
        The text may contain scanning errors ('scannos'). Correct these while preserving the original meaning and style of the text as much as possible. 
        
        Your response should maintain paragraphs and be structured as follows:
        <corrected_text>
        [The corrected text goes here, with no introductory phrases]
        </corrected_text>
        
        Text to proofread:
        `;
      }

      const claudeProofread = httpsCallable(functions, 'callClaude');
      const openAIProofread = httpsCallable(functions, 'callOpenAI');
      const geminiProofread = httpsCallable(functions, 'callGemini');


      let proofreadText = text;
      let tokensUsed = 0;

      try {
        // First attempt: Use OpenAI
        const result = await openAIProofread({ userInput: text, prompt: prompt });
        proofreadText = result.data.result || text;
        tokensUsed = result.data.tokenUsage.totalTokens;
      } catch (error) {
        console.error("Error processing request with OpenAI. Trying Gemini.", error);
        setScanningStatus("Consulting secondary AI model...");
        try {
          // Second attempt: Use Gemini
          const result = await geminiProofread({ userInput: text, prompt: prompt });
          proofreadText = result.data.result || text;
          tokensUsed = result.data.tokenUsage;
        } catch (error) {
          console.error("Error processing request with Gemini. Trying Claude.", error);
          setScanningStatus("Consulting tertiary model...");

          try {
            // Third attempt: Use Claude
            const result = await claudeProofread({ userInput: text, prompt: prompt });
            proofreadText = result.data.result || text;
            tokensUsed = result.data.tokenUsage.totalTokens;
          } catch (error) {
            console.error("Error processing request with Claude. Returning raw text.", error);
            // Optionally handle the case where all AI models fail
            // e.g., setScanningStatus("All models failed. Please try again later.");
          }
        }
      }

      // Handle response

      console.log(tokensUsed);
      console.log("OPENAITEXTRECEIVED", proofreadText);

      updateTokenCount(tokensUsed);

      // Remove page contiuations at the end of text
      // Page continuations are only needed at the beginning of pages
      if (ocrSettings.pageContinued) {
        // Remove existing "[...]" if it's at the end of the text
        proofreadText = proofreadText.replace(/\s?\[\.\.\.\]$/, '').trim();

        // Check if the first letter is lowercase and "[...]" is not present
        if (/^[a-z]/.test(proofreadText) && !/^\[\.\.\.\]/.test(proofreadText)) {
          proofreadText = '[...] ' + proofreadText;
        }
      }

      // Customize the filter to censor only offensive words and preserve punctuation
      function sanitizeText(text) {
        // Use a regular expression to match words, keeping punctuation intact
        return text.replace(/\b\w+\b/g, (word) => {
          if (filter.isProfane(word)) {
            return word[0] + '*'.repeat(word.length - 1);  // Censor the word
          }
          return word;  // Return non-offensive words unchanged
        });
      }

      if (ocrSettings.textSanitizer) {

        proofreadText = sanitizeText(proofreadText);

      }

      console.log('beforesetgoogleocrtext', proofreadText)
      setGoogleOcrText(proofreadText);
      console.log('aftersetgoogleocrtext')
      await delay(3000);
      if (!autoScan) {
        handleStartSpeechFromScan(proofreadText);
      }

      console.log('test1')

      staticLogo.style.display = "block";
      animatedLogo.style.display = "none";
      setIsLoading(false);
      setScanningStatus("RoboEdit Smart OCR Scanner");

      const editor = document.getElementById('editor');
      if (editor) {
        editor.focus();
        console.log('test3');
      } else {
        console.warn('Editor element not found.');
      }

      console.log('before aiproofread return', proofreadText)
      return proofreadText

    } catch (error) {
      setScanningStatus("RoboEdit Smart OCR Scanner");
      setIsLoading(false)
      try {
        // Your code that might throw an error
      } catch (error) {
        if (error.message.toLowerCase().includes("internal")) {
          // Do something specific for internal errors
          alert("Scan aborted")
          // Add your specific actions here
        } else {
          // Handle other types of errors
          alert(error);
        }
      }
    }
  };

  const renderEditorContent = () => {

    if (isLoadingTts) {
      if (coverUrl) {
        return <img className='shimmer' width='70%' src={coverUrl} />;
      } else {
        return <div className='TTSLoader'>
          <center>
            <SpinningCircleLoader {...loaderProps} />
            <p>{getRandomWittyPhrase()}</p>
          </center>
        </div>
      }
    }

    if (isLoading) {
      if (coverUrl) {
        return <img className='shimmer' width='70%' src={coverUrl} />;
      } else {
        return (
          <div className='TTSLoader'>
            <center>
              <NotepadLoader {...notepadLoaderProps} />
            </center>
          </div>
        );
      }
    }

    if (editMode) {
      return (
        <MDEditor
          id="editor"
          preview="edit"
          textareaProps={{
            id: 'editor',
            fontSize: 30,
            placeholder: 'No text scanned for this page yet',
            maxLength: 30000,
            spellCheck: true,
          }}
          height="100%"
          hideToolbar={false}
          value={googleOcrText}
          onChange={handleGoogleOcrTextChange}
          commands={[
            // Custom Toolbars
            bold,
            italicize,
            superscript,
            emDash,
            commands.divider,
            title3,
            warpedText,
            standardizeLineBreaks,
            textContinued,
          ]}
        />
      );
    }

    if (ocrSettings.ttsEngine === 'browserDefault') {
      return (
        <div
          ref={textContainerRef}
          className='tts-mode-default'
          style={{ textAlign: 'left', paddingBottom: '30px' }}
          dangerouslySetInnerHTML={{ __html: prepareTextForHighlighting(googleOcrText) }}
        />
      );
    }

    if (audioData && audioData.text) {
      return (
        <div id="tts-mode">
          {getHighlightedText()}
        </div>
      );
    }

    return coverUrl
      ? <img className='shimmer' width='70%' src={coverUrl} />
      : (
        <div className='TTSLoader'>
          <center>
            <SpinningCircleLoader {...loaderProps} />
            <p>{getRandomWittyPhrase()}</p>
          </center>
        </div>
      );
  };

  const handleTextChange = (event) => {
    setGoogleOcrText(event.target.value); // Update the state with the new text
  };

  function checkForChapterTitle(text) {

    console.log(checkForChapterTitle)
    // Regular expression to find patterns like '###33' or '###' followed by any number
    const chapterPattern = /###\s*(\d+)/g;

    // Split the text into lines for easier manipulation
    let lines = text.split('\n');

    // Iterate over the lines and check each line for the chapter pattern
    for (let i = 0; i < lines.length; i++) {
      let line = lines[i];
      if (chapterPattern.test(line)) {
        const isChapter = window.confirm(`Is this a chapter title? ${line}`);
        if (!isChapter) {
          // If not a chapter title, ask if the line should be removed
          const removeLine = window.confirm("Do you want to remove this line?");
          if (removeLine) {
            // Remove the line from the array of lines
            lines.splice(i, 1);
            // Adjust the index to reflect the removed line
            i--;
          }
        }
      }
    }

    // Rejoin the lines back into a single string
    return lines.join('\n');
  }


  const fixedCanvasWidth = 500;
  const fetchPDF = async (pageNum) => {
    setIsLoadingPage(true)
    if (!uploadedFileUrl) return;
    const pdfjsLib = await import('pdfjs-dist');
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      "https://cdn.jsdelivr.net/npm/pdfjs-dist@4.2.67/build/pdf.worker.min.mjs";

    try {
      setIsRendered(false); // Reset the rendered state
      const pdf = await pdfjsLib.getDocument(uploadedFileUrl).promise;
      setIsLoadingPage(false)
      setNumPages(pdf.numPages); // Set the total number of pages

      const page = await pdf.getPage(pageNum);
      const viewport = await page.getViewport({ scale: 1 });

      // Adjust canvas dimensions to emphasize width
      const canvas = canvasRef.current;
      const viewportWidth = window.innerWidth * 0.4; // Use 80% of viewport width for a wider canvas
      const canvasHeight = window.innerHeight * 0.8; // Maintain a significant height


      // Scale to maximize the width utilization
      const scale = viewportWidth / viewport.width;

      const scaledViewport = page.getViewport({ scale });

      canvas.width = scaledViewport.width;
      canvas.height = scaledViewport.height;

      setTextAreaHeight(canvas.height)

      const context = canvas.getContext('2d');

      const renderContext = {
        canvasContext: context,
        viewport: scaledViewport,
      };
      await page.render(renderContext).promise;
      setIsLoadingPage(false)
      setIsRendered(true); // Set rendered to true after the page is drawn
      return pdf.numPages;
    } catch (error) {
      setIsLoadingPage(false)
      console.error('Error loading or rendering PDF: ', error);
    }
  };

  useEffect(() => {
    if (isPlaying) {
      setIsHidden(false); // Ensure button is visible
    } else {
      // Delay hiding until animation is complete
      const timeout = setTimeout(() => setIsHidden(true), 3000); // 3000ms matches the animation duration
      return () => clearTimeout(timeout);
    }
  }, [isPlaying]);


  useEffect(() => {
    const fetchVoices = () => {
      const synth = window.speechSynthesis;
      let availableVoices = synth.getVoices().filter(voice => voice.lang.startsWith('en'));

      if (availableVoices.length !== 0) {
        setVoices(availableVoices);
        selectVoice(availableVoices);
      } else {
        synth.onvoiceschanged = () => {
          availableVoices = synth.getVoices().filter(voice => voice.lang.startsWith('en'));
          setVoices(availableVoices);
          selectVoice(availableVoices);
        };
      }
    };



    const selectVoice = (availableVoices) => {

      let preferredVoice;
      // Try to find the Google UK English Male voice first
      // let preferredVoice = availableVoices.find(voice => voice.name === 'Google UK English Male');

      // // If not found, try to find the Microsoft AndrewMultilingual Online voice
      if (!preferredVoice) {
        preferredVoice = availableVoices.find(voice =>
          voice.name.toLowerCase().includes('microsoft') &&
          voice.name.toLowerCase().includes('andrew') &&
          voice.name.toLowerCase().includes('multilingual')
        );
      }

      console.log(preferredVoice)

      // If neither is found, fall back to the first available voice
      if (!preferredVoice) {
        console.log("preferred voice not found")
        preferredVoice = availableVoices[0];
      }

      if (preferredVoice) {
        setSelectedVoice(preferredVoice);
        setIsVoiceReady(true); // Indicate that the voice is ready
      }

      setSelectedVoice(preferredVoice);
      console.log('done selecting voice')
    };

    fetchVoices();
  }, []);

  useEffect(() => {
    if (isVoiceReady && selectedVoice) {
      // Start the speech synthesis after the voice is selected
      const utterance = new SpeechSynthesisUtterance("");
      utterance.voice = selectedVoice;
      window.speechSynthesis.speak(utterance);
    }
  }, [isVoiceReady, selectedVoice]);

  useEffect(() => {
    async function fetchPDFData() {
      const numpages = await fetchPDF(currentPage, uploadedFileUrl);
      await getTokenCount();
      await fetchOCRSettings();
      if (isFirstLoad.current) {
        fetchOcrProgress(numpages);
        isFirstLoad.current = false;
      }
    }

    async function fetchOcrProgress(numpages) {
      try {
        const docRef = doc(db, "users", user, "books", book.title);
        const document = await getDoc(docRef); // Implement getDocument to fetch the document from your database
        const ocrPage = document.data().ocrProgress;

        if (ocrPage !== null && ocrPage !== undefined) {
          setOcrProgress(ocrPage);
          setGotoPage(ocrPage);
          handleJumpToPage(null, ocrPage, numpages);
        }
      } catch (error) {
        console.error("Error fetching OCR progress:", error);
      }
    }
    fetchPDFData()
  }, [uploadedFileUrl, currentPage]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);


  useEffect(() => {
    // Simulate fetching proofread text from an API or method
    const fetchProofreadText = async () => {
      const result = await getTextFromMethod();
    };

    fetchProofreadText();
  }, []);

  useEffect(() => {
    highlightWord(highlightedIndex);
  }, [highlightedIndex]);

  const highlightWord = (index) => {
    if (!textContainerRef.current) return;

    const spans = textContainerRef.current.querySelectorAll('span');
    spans.forEach((span, i) => {
      if (i === index) {
        span.style.backgroundColor = '#9F9589';
      } else {
        span.style.backgroundColor = 'transparent';
      }
    });
  };

  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  const pauseAudio = () => {
    if (audioRef.current) {
      setSavedTime(audioRef.current.currentTime); // Save current time
      audioRef.current.pause();
      setShow(true)
      setIsPlaying(false);
    }
  };

  const resumeAudio = () => {
    if (audioRef.current && audioRef.current.paused) {
      audioRef.current.currentTime = savedTime; // Resume from saved time
      audioRef.current.play().then(() => {
        setShow(false)
        setIsPlaying(true);
      }).catch((error) => {
        console.error("Error resuming audio:", error);
      });
    }
  };

  const stopAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setSavedTime(0); // Reset saved time
      setIsPlaying(false);
      setShow(true)
      setResetAudio(true)
      setEditMode(true)
    }
  };

  const regenerateAudio = async (googleOcrText) => {

    const proceed = window.confirm("Are you sure you want to regenerate audio? This action will require tokens AND your original audio will be overwritten.");
    if (!proceed) {
      return
    }
    setIsLoadingTts(true)
    setIsLoading(true)
    await handleGenerateSpeech(googleOcrText, selectedPremiumVoice, currentPage);
    setIsLoadingTts(false)
    setIsLoading(false)
  }

  const playAudioClicked = async (audioData) => {




    if (ocrSettings.readMode) {
      return handleReadMode(currentPage);
    }

    console.log('Passed to playAudioClicked:', audioData);



    let audioDataObj = audioData;

    console.log('audiodataobj', audioDataObj)


    // If there was no audio data initially, generate it
    if (!audioDataObj) {

      if (!googleOcrText || googleOcrText.trim() === '') {
        return alert("There is no text to generate speech for. Please first add some text.")
      }

      try {
        audioDataObj = await handleGenerateSpeech(googleOcrText, selectedPremiumVoice, currentPage);
        console.log('Generated new audio data:', audioDataObj);
        setAudioData(audioDataObj);
      } catch (error) {
        console.error('Error generating audio:', error);
        return; // Exit if there's an error
      }
    } else {
      if (audioDataObj.text !== googleOcrText) {
        console.log(audioDataObj.text)
        console.log(googleOcrText)
        stopAudio()
        setAudioData(null)
        try {
          audioDataObj = await handleGenerateSpeech(googleOcrText, selectedPremiumVoice, currentPage);
          console.log('Generated new audio data:', audioDataObj);
          setAudioData(audioDataObj);
        } catch (error) {
          console.error('Error generating audio:', error);
          return; // Exit if there's an error
        }
      }
    }

    const url = audioDataObj.url
    const message = audioDataObj

    console.log('Audio URL and message:', url, message);

    setEditMode(false);

    if (isPlaying) {
      setShow(false);
    }
    if (audioRef.current && audioRef.current.paused && !resetAudio) {
      console.log('Resuming audio...');
      resumeAudio();
    } else {
      console.log('Starting audio from the beginning...');
      setHasScrolled(false)
      setResetAudio(false)
      playAudio(url, message);
    }
  };

  const binarySearchWordIndex = (timestamps, currentTime) => {
    let left = 0;
    let right = timestamps.length - 1;

    while (left <= right) {
      const mid = Math.floor((left + right) / 2);
      const { start, end } = timestamps[mid];

      if (currentTime >= start && currentTime <= (end + 0.5)) {
        return mid; // Found the matching index
      } else if (currentTime < start) {
        right = mid - 1; // Search the left half
      } else {
        left = mid + 1; // Search the right half
      }
    }

    return -1; // If no match is found
  };


  const normalizeWord = (word) => {
    return word
      .replace(/--+/g, '—') // Replace multiple dashes with a single em dash
      .replace(/—/g, '—') // Normalize em dashes
      .replace(/[^\w\s]/g, '') // Remove punctuation (optional, if needed)
      .toLowerCase(); // Ensure case-insensitivity
  };

  const playAudio = (url, audioData) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    const audioUrl = new URL(url);
    console.log(audioUrl)
    audioRef.current = new Audio(audioUrl.href);

    audioRef.current.playbackRate = 1.0;

    setIsPlaying(false);
    audioRef.current.play().then(() => {
      setShow(false);
      setIsPlaying(true);
    }).catch(() => {
      setIsPlaying(false);
      setShow(true);
    });

    const handleSpeechEnd = () => {
      setIsPlaying(false);
      setResetAudio(false);
      setShow(true);
      setHasScrolled(false);
      setCurrentWordIndex(-5);
    };

    audioRef.current.addEventListener('ended', handleSpeechEnd);

    let lastScrollSegment = 0;  // Track the last scroll segment

    audioRef.current.addEventListener('timeupdate', () => {
      const currentTime = audioRef.current.currentTime;
      const totalTime = audioRef.current.duration;  // Total duration of the audio
      const totalScrolls = 5;  // Number of times you want to scroll
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight;  // Total scrollable height
      const segmentTime = totalTime / totalScrolls;  // Divide total time into segments

      // Calculate the current segment
      const currentSegment = Math.floor(currentTime / segmentTime);

      // // If we've entered a new segment, scroll proportionally
      // if (currentSegment > lastScrollSegment && currentSegment <= totalScrolls) {
      //   const scrollPercentage = (currentSegment / totalScrolls);  // Calculate percentage of scroll
      //   const scrollPosition = scrollPercentage * totalHeight;  // Calculate the scroll position

      //   // Scroll to the calculated position
      //   scroll.scrollTo(scrollPosition, {
      //     duration: 1000,  // Duration of scroll (can be adjusted)
      //     smooth: 'easeInOutQuad',
      //   });

      //   // Update the last scrolled segment
      //   lastScrollSegment = currentSegment;
      // }

      // Get the expected word index based on timestamps
      let currentWordIndex = audioData.timestamps.findIndex((wordTiming) => {
        return currentTime >= wordTiming.start && currentTime <= wordTiming.end;
      });

      if (currentWordIndex !== -1) {
        // Snap the current word index to the closest match without adjusting playback speed
        setCurrentWordIndex(currentWordIndex);

        // Get the highlighted word element
        const highlightedWord = document.getElementById(`word-${currentWordIndex}`);

        if (highlightedWord) {
          const container = document.getElementById('md-editor');  // Reference to the container
          const wordPosition = highlightedWord.getBoundingClientRect();  // Get the word's position relative to the viewport
          const containerPosition = container.getBoundingClientRect();  // Get the container's position

          // Calculate the word's position relative to the container
          const wordPositionInContainer = wordPosition.top - containerPosition.top;

          // Determine the current scroll position of the container
          const scrollTop = container.scrollTop;

          // Calculate the visible height of the container
          const containerHeight = container.clientHeight;

          // Check if the highlighted word is within 200px from the bottom of the visible area
          if (wordPositionInContainer > containerHeight - 400) {  // 200px from the bottom of the container
            // Calculate the target scroll position
            const scrollTargetPosition = scrollTop + wordPositionInContainer;

            // Use react-scroll's animateScroll to smoothly scroll to the target position
            scroll.scrollTo(scrollTargetPosition, {
              duration: 2000, // Duration of scroll (in milliseconds)
              smooth: 'easeInOutQuad',  // Easing function for smooth scroll
              containerId: 'md-editor',  // ID of the container (important for scrolling within the specific element)
            });
          }
        }

        // Update the last word index for future comparisons
        setLastWordIndex(currentWordIndex);  // Update lastWordIndex here
      }
    });
  };

  const getHighlightedText = () => {
    // Split the text by words and also handle line breaks
    const wordArray = audioData.text.match(/\S+|\n/g); // Match words and newlines

    let wordCount = 0; // Keep track of the actual word index, ignoring line breaks

    return wordArray.map((word, index) => {
      // If the word is a newline, return a line break
      if (word === '\n') {
        return <br key={`br-${index}`} />;
      }

      // Render the word with possible HTML tags and highlight based on current index
      const isHighlighted = currentWordIndex >= wordCount && currentWordIndex - wordCount <= 2;
      wordCount++; // Increment wordCount only for actual words

      return (
        <span
          key={index}
          className={isHighlighted ? 'highlight' : ''} // Apply highlight to words within the range
          id={`word-${index}`}
          dangerouslySetInnerHTML={{ __html: word + ' ' }} // Render HTML inside the word
        />
      );
    });
  };

  const prepareTextForHighlighting = (text) => {
    // Split the text into lines
    const lines = text.split('\n');

    // Process each line separately
    return lines.map(line =>
      line.split(/(\s+)/).map((part, index) => {
        if (part.trim() === '') {
          return part; // Preserve spaces
        } else {
          return `<span data-index="${index}">${part}</span>`; // Wrap words in spans
        }
      }).join('')
    ).join('<br>'); // Join lines with <br> tags
  };


  const handleStartSpeechAutoScan = (googleOcrText) => {
    console.log("handleStartSpeechAutoScan")
    setEditMode(false)
    startSpeech(googleOcrText);
  };

  const handleStartSpeechFromScan = (text, timestamps) => {
    console.log("handleStartSpeechFromScan")
    setEditMode(false)
    startElevenlabsSpeech(text, timestamps, () => {
      console.log('Speech has ended.');
    });
  };

  // Control Functions
  const handleStartSpeech = () => {
    console.log('handle start')
    if (isPaused) {
      console.log('is paused flow')
      handleResumeSpeech();
    } else {
      console.log('handle')
      setEditMode(false);
      setShow(false);
      setIsPlaying(true);
      const text = googleOcrText;
      startSpeech(text)
    }
  };

  const handleStopSpeech = () => {
    console.log("handle stop")
    stopSpeech();
    setIsPlaying(false);
    setEditMode(true);
    setShow(true);
    setIsPaused(false); // Ensure the paused state is reset
  };

  const handlePauseSpeech = () => {
    console.log("handle pause")
    setIsPlaying(false);
    setIsPaused(true);
    pauseSpeech();
  };

  const handleResumeSpeech = () => {
    console.log("resume speech")
    setIsPaused(false);
    setShow(false)
    setIsPlaying(true);
    resumeSpeech();
  };


  const getTextFromMethod = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve('Hello, this is the text returned from the method.');
      }, 1000);
    });
  };

  // const highlightTextInDiv = (index) => {
  //   const div = document.querySelector('.tts-mode');
  //   if (div) {
  //     const text = div.innerText;
  //     const words = text.match(/[\w'-]+|[.,!?;]|\s/g); // Regex to match words, punctuation, and spaces
  //     div.innerHTML = words
  //       .map((word, i) => (i === index ? `<span style="background-color: yellow;">${word}</span>` : word))
  //       .join('');
  //   }
  // };

  // useEffect(() => {
  //   highlightTextInDiv(highlightedIndex);
  // }, [highlightedIndex]);



  const goToBook = async () => {
    const docRef = doc(db, "users", user, "books", book.title);
    console.log("users/" + user + "/books/" + book.title)
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      navigate("/books/contents", {
        state: {
          book: book
        },
      });
    } else {
      return alert("Something went wrong.");
    }
  }

  const handleScanDialogClose = () => {
    setScanDialogOpen(false)
  }

  const convertTextToHtml = (text) => {
    return text.replace(/\n/g, '<br/>');
  };

  const handleScanDialogOpen = () => {
    if (!uploadedFileUrl) {
      alert("Please upload a PDF first. There is nothing to scan currently.")
      return
    }
    setScanDialogOpen(true)
  }

  const handleSingleScan = () => {
    if (ocrSettings.soundEffectsEnabled) {
      playPcBoot()
    }
    setScanDialogOpen(false)



    { ocrSettings.ocrEngine == 'docai' ? googleVision() : tesseractOCR() }
  }

  const fetchOCRSettings = async () => {
    try {
      const bookRef = doc(db, 'users', user, 'books', book.title);
      const bookDoc = await getDoc(bookRef);

      if (bookDoc.exists()) {
        const data = bookDoc.data();
        if (data.ocrSettings) {
          setOcrSettings(data.ocrSettings);
          setSelectedPremiumVoice(data.ocrSettings.premiumVoice || '6AKHxakv0gUvEwsGQuHh')
        }
        if (data.coverUrl) {
          setCoverUrl(data.coverUrl)
        }
      }
    } catch (error) {
      console.error('Error fetching OCR settings:', error);
    }
  };



  const handleOcrSettingChange = async (settingName, value) => {

    if (settingName === 'ttsEnabled' && value === false) {
      stopAudio()
    }
    // Update local state
    const updatedSettings = { ...ocrSettings, [settingName]: value };

    // If maintainParagraphs is set to true, pageContinued must be false
    if (settingName === 'maintainParagraphs' && value === true) {
      updatedSettings.pageContinued = false;
    }

    // If pageContinued is set to true, maintainParagraphs must be false
    if (settingName === 'pageContinued' && value === true) {
      updatedSettings.maintainParagraphs = false;
    }

    setOcrSettings(updatedSettings);

    console.log('Updated Settings:', updatedSettings);

    try {
      // Update Firestore
      const bookRef = doc(db, 'users', uid, 'books', book.title);
      await setDoc(bookRef, {
        ocrSettings: updatedSettings
      }, { merge: true });
      console.log('OCR settings updated successfully');
    } catch (error) {
      console.error('Error updating OCR settings:', error);
      // Optionally, revert the local state change if the Firestore update fails
      // setOcrSettings(ocrSettings);
    }
  };

  const handleAutoScan = async (currentPage) => {
    console.log("AUTOSCAN started");
    if (ocrSettings.soundEffectsEnabled) {
      playPcBoot()
    }
    setAutoScan(true)


    setScanDialogOpen(false);

    let localCurrentPage = currentPage; // Initialize with the state value

    const readpageTextElevenlabs = async (googleOcrText, selectedPremiumVoice) => {

      console.log('inreadpage', googleOcrText);
      const data = await handleGenerateSpeech(googleOcrText, selectedPremiumVoice, localCurrentPage);
      console.log(data);

      return new Promise((resolve) => {
        const handleSpeechEnd = () => {
          const currentTime = new Date().toLocaleTimeString();
          console.log(`AUTOSCAN - Speech has finished at ${currentTime}.`);
          resolve();
        };

        setShow(false);
        setIsPlaying(true);
        playAudio(data.url, data);

        // Add event listener to resolve the promise when audio ends
        audioRef.current.addEventListener('ended', handleSpeechEnd);
      });
    };

    for (let i = 0; i < numPages; i++) {

      /* If the user clicks stop auto scan button, the auto scan will stop */
      if (!autoScan) {
        setShowStopAutoScan(false);
        /* It needs to be reset to its default stage for the next autoscan */
        return setShowStopAutoScan(true);
      }

      await delay(3000);
      console.log(`Starting scan for page ${i + 1}`);

      let googleOcrText = "";

      if (ocrSettings.ocrEngine === 'docai') {
        googleOcrText = await googleVision();
      } else {
        googleOcrText = await tesseractOCR();
      }
      console.log("LOOP", googleOcrText)

      if (ocrSettings.ttsEnabled) {
        setEditMode(false)
        console.log(googleOcrText)
        await readpageTextElevenlabs(googleOcrText, selectedPremiumVoice); // Read the updated text
        scroll.scrollToTop({
          duration: 2000, // Time in milliseconds for the scroll to complete
          smooth: 'easeInOutQuad', // Easing function
        });
        await delay(3000); // Additional delay after reading
      } else {
        await delay(3000);
      }

      if (localCurrentPage < numPages - 1) {
        setAudioData(null)
        document.getElementById('next-btn').click();
        localCurrentPage++; // Manually increment since state updates are not synchronous
      }
    }
    console.log('AutoScan complete');
  };



  const handleReadMode = async (page) => {
    console.log("READ MODE started");
    console.log("current useState page", currentPage)
    setAutoScan(true);
    setShowStopAutoScan(true);
    setScanDialogOpen(false);

    let localCurrentPage = page; // Initialize with the current page

    const readPage = async (page) => {
      console.log("before fb read");
      console.log('page from local', page)
      let data;
      let text;
      let pageStr = page.toString();
      const docRef = doc(db, "users", user, "books", book.title, "ocrscan", pageStr);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        data = docSnap.data().audioData;
        text = docSnap.data().content;
      } else {
        console.log("No such audio data!");
        return;
      }
      if (!data || data.url === null) {
        console.log('before write', localCurrentPage, googleOcrText)
        data = await handleGenerateSpeech(text, selectedPremiumVoice, localCurrentPage);
      }

      return new Promise((resolve) => {
        const handleSpeechEnd = () => {
          const currentTime = new Date().toLocaleTimeString();
          console.log(`AUTOSCAN - Speech has finished at ${currentTime}.`);
          resolve();
          audioRef.current.removeEventListener('ended', handleSpeechEnd);
        };

        setShow(false);
        setIsPlaying(true);

        try {
          if (data.url) {
            playAudio(data.url, data);
            audioRef.current.addEventListener('ended', handleSpeechEnd);
          }
        } catch (error) {
          console.error("An error occurred while playing the audio or adding the event listener:", error);
          alert("Disable reading mode if error persists.");
          return;
        }
      });
    };

    while (localCurrentPage < numPages + 1) {

      console.log(localCurrentPage, numPages)
      // Stop auto-scan if the user interrupts
      if (!autoScan) {
        setShowStopAutoScan(false);
        return setShowStopAutoScan(true);
      }

      if (ocrSettings.ttsEnabled && googleOcrText) {
        console.log(googleOcrText);
        setEditMode(false);

        await readPage(localCurrentPage); // Read the updated text

        scroll.scrollToTop({
          duration: 2000,
          smooth: 'easeInOutQuad',
        });
        await delay(2000);
      } else {
        await delay(2000);
      }

      // Increment and check if it’s the last page before next action
      localCurrentPage++;
      if (localCurrentPage < numPages + 1) {
        setAudioData(null);
        document.getElementById('next-btn').click();
        await delay(3000);
      }
    }
    console.log('Read mode complete');
    document.getElementById('stop-auto-scan').click();

  };

  const handleGenerateSpeech = async (googleOcrText, voice, page) => {

    if (!googleOcrText || googleOcrText.trim() === '') {
      return alert("There is no text to generate speech for. Please first add some text.")
    }

    console.log("Generating AI speech");
    const generateSpeechWithTimestamps = httpsCallable(functions, 'generateSpeechWithTimestamps', { timeout: 540000 });

    try {
      setEditMode(false);

      // Count characters and calculate tokens used
      const characterCount = googleOcrText.length;
      const tokensUsed = characterCount * 8; // 8 tokens per character

      // Generate speech and get result
      const result = await generateSpeechWithTimestamps({ text: googleOcrText, voice: voice });
      const generatedAudioData = result.data || null;

      // Post clean up - should be handled upstream but preserving here if needed
      if (generatedAudioData) {
        const cleanedText = googleOcrText.replace(/–|—/g, '--');
        const wordArray = cleanedText.match(/\S+/g);
        const words = generatedAudioData.timestamps;

        console.log(wordArray)
        console.log('before')

        for (let i = 0; i < wordArray.length; i++) {
          // Check that i+1 is in bounds for both arrays
          if (i + 1 < wordArray.length && i + 1 < words.length) {

            // If the current and next words don't match between wordArray and timestamps
            if (wordArray[i] !== words[i].word && wordArray[i + 1] !== words[i + 1].word) {

              // Merge the current and next word in the timestamps array
              words[i] = {
                word: words[i].word + words[i + 1].word,
                start: words[i].start,
                end: words[i + 1].end
              };

              // Remove the next entry from the timestamps array after merging
              words.splice(i + 1, 1);

            }
          } else {
            console.log('exiting loop to avoid out of range');
            break;
          }
        }

        console.log('after')
        console.log(wordArray.length)
        console.log(words.length)

        generatedAudioData.timestamps = words;

      }

      // Update audioData state
      setAudioData(generatedAudioData);


      console.log("BEFORE SET DOC IN HandleGenerateSpeech", page)
      // Update Firestore document
      // Save the newly generated audio data and sync with the current content
      await setDoc(
        doc(db, "users", user, "books", book.title, "ocrscan", page.toString()),
        {
          audioData: generatedAudioData, // Ensure this uses the updated data
          content: googleOcrText
        },
        { merge: true }
      );

      // Update token count in Firestore
      await updateTokenCount(tokensUsed);

      // Return the result data
      return generatedAudioData;

    } catch (error) {
      console.error('Error generating speech:', error);
      alert("There was an error generating text-to-speech.")
      setEditMode(true)
      throw error; // Optionally re-throw the error to be handled by the caller
    }
  };

  const handleGoogleOcrTextChange = (value) => {
    // Replace curly single and double quotes with straight quotes
    const straightenedValue = value
      .replace(/‘|’/g, "'")  // Replace curly single quotes
      .replace(/“|”/g, '"')  // Replace curly double quotes
      .replace(/\s?\.\.\.\s?/g, '...'); // Replace ' ... ' and similar spaces around ellipsis

    // Set the new value from the editor
    setGoogleOcrText(straightenedValue);
  };


  return (

    <div>
      <LinearProgress className="Linear" variant="determinate" color='info' value={progress} />
      <FadeInOut show={show}>
        <Tooltip title="Table of Contents">
          <Fab
            className="FabTocOCR"
            disabled={isLoading}
            variant='contained'
            color='secondary' onClick={() => goToBook()}><TocIcon /></Fab>
        </Tooltip>
      </FadeInOut>
      {uploadedFileUrl &&
        <FadeInOut show={show}>
          <div className='FabComplete'>
            <Tooltip title="Complete OCR Scan and Generate Source OCR">
              <Fab
                disabled={isLoading}
                variant='extended'
                color='secondary'
                onClick={() => handleOpenAlert()}><SuccessIcon sx={{ mr: 1 }} />Complete
              </Fab>
            </Tooltip>
          </div>
          <div className='FabCompleteSmall'>
            <Tooltip title="Complete OCR Scan and Generate Source OCR">
              <Fab
                disabled={isLoading}
                variant='contained'
                color='secondary'
                onClick={() => handleOpenAlert()}><SuccessIcon />
              </Fab>
            </Tooltip>
          </div>
        </FadeInOut>
      }

      {ocrSettings.ttsEnabled && <div className="TTSIcons">

        {ocrSettings.ttsEngine === 'browserDefault' && <div className="TTSIcons">
          {isPlaying ? <IconButton onClick={handlePauseSpeech} aria-label="start" size="small">
            <PauseIcon style={{ color: 'var(--text-color)' }} fontSize="inherit" />
          </IconButton> : <IconButton onClick={handleStartSpeech} aria-label="start" size="small">
            <PlayIcon style={{ color: 'var(--text-color)' }} fontSize="inherit" />
          </IconButton>}
          <IconButton onClick={handleStopSpeech} aria-label="start" size="small">
            <StopIcon style={{ color: 'var(--text-color)' }} fontSize="inherit" />
          </IconButton>
        </div>
        }

        {ocrSettings.ttsEngine === 'elevenlabs' && <Fragment>
          {(isPlaying) ? (
            <Tooltip title="Pause" placement='top'>
              <IconButton onClick={() => pauseAudio()} aria-label="pause" size="small">
                <PauseIcon style={{ color: 'var(--text-color)' }} fontSize="inherit" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Play" placement='top'>
              <IconButton onClick={() => playAudioClicked(audioData)} aria-label="play" size="small">
                <PlayIcon style={{ color: 'var(--text-color)' }} fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Stop" placement='top'>
            <IconButton onClick={() => stopAudio()} aria-label="stop" size="small">
              <StopIcon style={{ color: 'var(--text-color)' }} fontSize="inherit" />
            </IconButton>
          </Tooltip>
          {ocrSettings.ttsEngine === 'elevenlabs' && <FadeInOut show={show}>
            <Tooltip
              placement='left'
              title={
                audioData
                  ? (audioData.text === googleOcrText
                    ? "Premium voiceover generated" // Text matches with OCR
                    : "Text and TTS out of sync")  // Text exists but doesn't match OCR
                  : "Premium voiceover not yet generated" // No audio data available
              }          >
              <div style={{ alignItems: 'center', marginTop: '15px' }}>
                <RecordVoiceOverIcon
                  style={{
                    color: 'var(--text-color)',
                    fontSize: 'inherit',
                    fontSize: '20px',
                    opacity: (ocrSettings.ttsEngine === 'elevenlabs' && audioData && audioData.text === googleOcrText) ? 1 : 0.5,
                    // Fully visible if all conditions are true, otherwise faded
                    pointerEvents: (ocrSettings.ttsEngine === 'elevenlabs' && audioData && audioData.text === googleOcrText) ? 'auto' : 'none'
                    // Disable pointer events if faded
                  }}
                  fontSize="inherit"
                />
                {(ocrSettings.ttsEngine === 'elevenlabs' && audioData && audioData.text === googleOcrText) && (
                  <CheckIcon
                    style={{
                      color: 'green',  // Color for the checkmark
                      fontSize: '20px',
                      marginLeft: '4px'  // Space between the icon and the checkmark
                    }}
                  />
                )}
                {(ocrSettings.ttsEngine === 'elevenlabs' && googleOcrText && audioData && audioData.text !== googleOcrText) && (
                  <SyncProblemIcon
                    style={{
                      color: 'goldenrod',  // Color for the checkmark
                      fontSize: '20px',
                      marginLeft: '4px'  // Space between the icon and the checkmark
                    }}
                  />
                )}
              </div>
            </Tooltip>
            {(ocrSettings.ttsEngine === 'elevenlabs' && googleOcrText && audioData) && <Tooltip placement='left' title='Regenerate TTS audio.'>
              <IconButton
                color="primary"
                onClick={() => regenerateAudio(googleOcrText)}
                aria-label="regenerate audio">
                <AutoMode fontSize='small' />
              </IconButton>
            </Tooltip>}
          </FadeInOut>}
          <br></br>
          {ocrSettings.readMode &&
            <Tooltip placement='left' title='Read mode enabled'>
              <ReadModeIcon
                style={{
                  color: 'goldenrod',  // Color for the checkmark
                  fontSize: '20px',
                  marginLeft: '4px'
                }}
              />
            </Tooltip>
          }
        </Fragment>
        }
        {/* <button onClick={() => handleGenerateSpeech(googleOcrText)}>Generate TTS</button> */}

      </div>}
      {
        <FadeInOut show={show}>

          <div className="FabScan">
            <Tooltip title="Control + Shift + S">
              <Fab
                size="large"
                variant='extended'
                color='success'
                onClick={() => handleScanDialogOpen()}
                disabled={isLoading}
              >
                <ScanIcon sx={{ mr: 1 }} />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Scan&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Fab>
            </Tooltip>
          </div>
        </FadeInOut>
      }
      {
        <FadeInOut show={show}>

          <div className="FabScanSmall">
            <Tooltip title="Control + Shift + S">
              <Fab
                size="large"
                variant='contained'
                color='success'
                onClick={() => handleScanDialogOpen()}
                disabled={isLoading}
              >
                <ScanIcon />
              </Fab>
            </Tooltip>
          </div>
        </FadeInOut>
      }
      <FadeInOut show={show}>
        <div className='EngineText'>
          <b>OCR Engine</b>: {ocrSettings.ocrEngine === 'docai' ? 'Document AI' : 'Tesseract'}
        </div>
      </FadeInOut>
      {
        <FadeInOut show={show}>

          <Tooltip title="Adjust OCR settings">
            <Fab
              size="large"
              className="FabSettings"
              color='main'
              onClick={() => handleSettingsOpen()}
              disabled={isLoading}>
              <SettingsIcon /></Fab></Tooltip>
        </FadeInOut>

      }
      <div className="three-columns">
        <div className="column left"><div className="Logo">
          &nbsp;&nbsp;
          <br></br><br></br>



        </div>
        </div>

        <div className="pageTitle column center">
          <Typography
            align='center'
            sx={{ width: '100%', fontWeight: 'bold', flexShrink: 0 }}
            variant="h6"
            component="h6"
          >
            RoboEdit OCR Scanner
          </Typography>

          <Box sx={{ marginTop: '5px', position: 'relative', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
            {tokens !== null &&
              <CircularProgress
                color={tokens ? "info" : "info"}
                size={70}
                sx={{
                  borderRadius: "50%",
                  boxShadow: "inset 0 0 0px 1px lightgray",
                  backgroundColor: "lightgray",
                }}
                variant="determinate"
                value={calculateUsage(tokens, maxTokens)}
              />
            }
            <div id="logoStatic" style={{ position: 'absolute', top: '-3px' }}>
              <img src={logo} width="70px" height="auto" />
            </div>
            <div id="logoAnimated" className="box" style={{ position: 'absolute', top: '3px' }}>
              <img className='scanning-logo' src={logo} width="70px" height="auto" />
            </div>
          </Box>

          <Tooltip
            onClick={() => window.open('https://billing.stripe.com/p/login/8wM2b3eSd9zS64w7ss', '_blank').focus()}
            sx={{ cursor: 'pointer' }}
            placement="bottom"
            title={tokens.toLocaleString() + " tokens remaining this month."}
          >
            <Typography variant="caption" component="div" className="TokensText">
              {isLoading ? scanningStatus : usageText(tokens, maxTokens)}
            </Typography>
          </Tooltip>
        </div>
        <div className="column right">
        </div>
      </div>


      <Typography align='center' sx={{ width: '100%', fontWeight: 'bold', flexShrink: 0 }} variant="body" component="h4">
        {/* First we will scan your source PDF, perform an OCR using Document AI, and then use an LLM to perform the very first edits. */}
      </Typography>

      {/* {  pdfUrl 
        ? null
        : <FileUpload onFileUpload={handleUpload}/> 
        } */}
      <div>
      </div>

      <Grid container spacing={2}>
        <Grid
          item
          xs={6}
          style={{
            position: 'sticky',
            top: '0',
            height: '100vh',  // Full height of the viewport
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginTop: '30px',
            overflowY: 'auto',  // Allow scrolling
            scrollbarWidth: 'none',  // For Firefox
            msOverflowStyle: 'none'  // For Internet Explorer and Edge
          }}
          className="no-scrollbar"  // Adding this class for cross-browser compatibility
        >
          {/* <div style={{ marginBottom: '10px' }}>
      <Tooltip title="Control + Shift + LeftArrow">
        <Button startIcon={<LeftIcon />} variant='secondary' size='small' onClick={() => goToPreviousPage()} disabled={currentPage <= 1 || isLoading}>Previous</Button>
      </Tooltip>&nbsp;&nbsp;
      <Tooltip title="Control + Shift + RightArrow">
        <Button id='next-btn' endIcon={<RightIcon />} variant='contained' size='small' onClick={() => goToNextPage()} disabled={currentPage >= numPages || isLoading}>Next</Button>
      </Tooltip>
    </div> */}
          <div>

            {(uploadedFileUrl && numPages > 0) && <>
              <form onSubmit={handleJumpToPage}>
                <span style={{ marginRight: '20px' }}>Page <b>{currentPage}</b> of <b>{numPages}</b></span>
                <input id="jump-page" type="text" placeholder="Go to page..." value={gotoPage} onChange={(e) => setGotoPage(e.target.value)} />
                <Button startIcon={<SearchIcon />} size='small' color={prefersDarkMode ? 'white' : 'info'} disabled={isLoading} type="submit"></Button>
              </form>
            </>}
          </div>
          <div style={{ position: 'relative', flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1002 }}>
            {!uploadedFileUrl ? <FileUpload type='ocr' onFileUpload={handleUpload} />
              : isLoadingPage
                ? <Skeleton sx={{ marginTop: '50px' }} variant="rectangular" animation='wave' width='100%' height='100vh' />
                : (
                  <>
                    <canvas
                      ref={canvasRef}
                      id="the-canvas"
                      style={{
                        opacity: isRendered ? 1 : 0,
                        transition: 'opacity 0.1s ease-in',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain'
                      }}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '45%',
                        height: '100%',
                        cursor: 'pointer',
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        padding: '0 20px',
                      }}
                      onMouseEnter={(e) => {
                        if (currentPage <= 1) {
                          e.currentTarget.style.backgroundColor = 'rgba(50, 0, 0, 0.1)';
                        } else {
                          e.currentTarget.style.backgroundColor = 'rgba(0,0,0,0.05)';
                          e.currentTarget.querySelector('.arrow-icon').style.opacity = '1';
                        }
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                        e.currentTarget.querySelector('.arrow-icon').style.opacity = '0';
                      }}
                      onClick={() => goToPreviousPage(currentPage)}
                    >
                      <ArrowBackIosNewIcon className="arrow-icon" style={{ opacity: 0, transition: 'opacity 0.3s', marginTop: '50px' }} />
                    </div>
                    <div
                      id='next-btn'
                      style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        width: '45%',
                        height: '100%',
                        cursor: 'pointer',
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                        padding: '0 20px',
                      }}
                      onMouseEnter={(e) => {
                        if (currentPage >= numPages) {
                          e.currentTarget.style.backgroundColor = 'rgba(50, 0, 0, 0.1)';
                        }
                        else {
                          e.currentTarget.style.backgroundColor = 'rgba(0,0,0,0.05)';
                          e.currentTarget.querySelector('.arrow-icon').style.opacity = '1';
                        }
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = 'transparent';
                        e.currentTarget.querySelector('.arrow-icon').style.opacity = '0';
                      }}
                      onClick={() => goToNextPage()}
                    >
                      <ArrowForwardIosIcon className="arrow-icon" style={{ opacity: 0, transition: 'opacity 0.3s', marginTop: '50px' }} />
                    </div>
                  </>
                )
            }
          </div>
          {uploadedFileUrl && !isPlaying &&
            <FadeInOut show={show}>
              <Tooltip placement='top' title="Delete source PDF">
                <PDFSpeedDial onActionClick={handleActionClick} />
                {/* <Fab
                  className="FabBtnDeletePdf"

                  color='warning'
                  onClick={() => replaceSourcePDF()}
                  disabled={isLoading}><DeleteIcon /></Fab> */}
              </Tooltip>
            </FadeInOut>}
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            height: '100vh',  // Full height of the viewport
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            overflowY: 'auto',  // Enable scrolling within the container
            scrollbarWidth: 'none',  // For Firefox
            msOverflowStyle: 'none'  // For Internet Explorer and Edge
          }}
          className="no-scrollbar"
        >
          <div
            id="md-editor"
            style={{
              height: '100%',
              paddingRight: '100px',
              marginTop: '55px',
              overflowY: 'auto',
              scrollbarWidth: 'none',  // Hide scrollbar for Firefox
              msOverflowStyle: 'none'  // Hide scrollbar for Internet Explorer and Edge
            }}
            className="no-scrollbar"
            data-color-mode="system"
          >
            {renderEditorContent()}
          </div>
        </Grid>
        <Grid item xs={3}>

        </Grid>
      </Grid>
      <Refiner type='ocrScanner' bookTitle={book.title} aceOpen={isFullOcrOpen} ocrText={ocrText} user={user} book={book} clicked={handleFullOcrClose} />

      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
      >
        <DialogTitle>
          {"Compile pages into Source OCR text?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By proceeding, RoboEdit will combine the scanned pages into a single Source OCR text file that you can use to build your book.
          </DialogContentText><br></br>
          <DialogContentText id="alert-dialog-description">
            If you have existing Source OCR text, clicking "Continue" will <b>OVERWRITE</b> it. Please double check before continuing
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={handleAlertClose}>Cancel</Button>
          <Button variant='contained' color='error' onClick={combineDocs} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        open={settingsOpen}
        onClose={handleSettingsClose}
      >
        <DialogTitle>
          {"Settings"}
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {'RoboEdit first scans PDFs with an OCR engine, then uses AI for post-processing. On this page you can adjust the settings used during this process.'}
          </DialogContentText>
          <p className="settings-header">
            <b>OCR Engine</b>
          </p>
          <RadioGroup
            aria-labelledby="ocr-engine-radio-buttons-group-label"
            name="ocr-engine-radio-buttons-group"
            value={ocrSettings.ocrEngine}
            onChange={(e) => handleOcrSettingChange('ocrEngine', e.target.value)}
          >
            <FormControlLabel
              value="docai"
              control={<Radio />}
              label={

                'Document AI'


              }
            />
            <span className="settings-caption">
              <a
                href="https://cloud.google.com/document-ai?hl=en"
                target="_blank"
              >Document AI</a> is a Google Cloud Platform document processor.
            </span>
            <FormControlLabel
              value="tesseract"
              control={<Radio />}
              label={'Tesseract'
              }
            />
            <span className="settings-caption">
              <a
                href="https://en.wikipedia.org/wiki/Tesseract_(software)"
                target="_blank"
              >Tesseract</a> is a free, open-source, client-based engine. Faster but less accurate.
            </span>
          </RadioGroup><br></br>
          <hr></hr>
          <p className="settings-header">
            <b>Proofreader Settings</b>
          </p>
          <FormControlLabel
            control={
              <Checkbox
                checked={ocrSettings.aiProofreadEnabled}
                onChange={(e) => handleOcrSettingChange('aiProofreadEnabled', e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="AI Proofreading Enabled"
          />
          <br></br>
          <FormControlLabel
            control={
              <Checkbox
                checked={ocrSettings.maintainParagraphs}
                onChange={(e) => handleOcrSettingChange('maintainParagraphs', e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Maintain paragraphs"
          />
          <br />
          <span className="settings-caption">
            RoboEdit will scan two pages at a time to check for page continuations. <br></br><br></br><i><b>Note:</b> <b>Maintain paragraphs</b> and <b>Identify page continuations</b> cannot be enabled at the same time</i>
          </span>
          <br /><br />

          <br />
          {ocrSettings.aiProofreadEnabled && <Fragment>
            <p className="settings-header">
              Pre-Processing
            </p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ocrSettings.twoColumns}
                  onChange={(e) => handleOcrSettingChange('twoColumns', e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Two-column layout"
            />
            <br />
            <span className="settings-caption">
              Enable this setting for more accurate two-column layout OCR
            </span>
            <br />


            <br></br>
            <hr></hr>
            <p className="settings-header">
              Post-Processing
            </p>
            <FormControlLabel
              control={
                <Checkbox
                  checked={ocrSettings.removePageHeader}
                  onChange={(e) => handleOcrSettingChange('removePageHeader', e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Remove page headers"
            />
            <br />
            <span className="settings-caption">
              AI will remove page headers
            </span>
            <br /><br />



            <FormControlLabel
              control={
                <Checkbox
                  checked={ocrSettings.pageContinued}
                  onChange={(e) => handleOcrSettingChange('pageContinued', e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Identify page continuations"
            />
            <br />
            <span className="settings-caption">
              Identify pages that start mid-sentence and insert [...] at the beginning.
            </span>
            <br /><br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={ocrSettings.identifyChapters}
                  onChange={(e) => handleOcrSettingChange('identifyChapters', e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Identify chapter numbers"
            />
            <br />
            <span className="settings-caption">
              Identify chapter numbers and insert ### before them.
            </span>
            <br></br><br></br>

            <FormControlLabel
              control={
                <Checkbox
                  checked={ocrSettings.identifySuperscripts}
                  onChange={(e) => handleOcrSettingChange('identifySuperscripts', e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Identify superscripts"
            />
            <br />
            <span className="settings-caption">
              Identify superscripts and footnote markers in text and wrap them in a superscript tag.
            </span>
            <br></br><br></br>

            <FormControlLabel
              control={
                <Checkbox
                  checked={ocrSettings.identifyDashes}
                  onChange={(e) => handleOcrSettingChange('identifyDashes', e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Identify dashes"
            />
            <br />
            <span className="settings-caption">
              Identify dashes and hyphens and replace them with em dashes where appropriate.
            </span>
            <br></br>
          </Fragment>}
          <hr />
          <p className="settings-header">
            <b>Text to Speech (TTS) Options</b>
          </p>
          <FormControlLabel
            control={
              <Checkbox
                checked={ocrSettings.ttsEnabled}
                onChange={(e) => handleOcrSettingChange('ttsEnabled', e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Enable Text to Speech (TTS)"
          />
          <br />
          <span className="settings-caption">
            RoboEdit has a built in text-to-speech feature if you want to listen to your book as it is scanned.
          </span><br></br><br></br>
          {ocrSettings.ttsEnabled && <Fragment>
            <p className="settings-subheader">
              TTS Engine
            </p>
            <RadioGroup
              aria-labelledby="tts-engine-radio-buttons-group-label"
              name="tts-engine-radio-buttons-group"
              value={ocrSettings.ttsEngine}
              onChange={(e) => handleOcrSettingChange('ttsEngine', e.target.value)}
            >
              <FormControlLabel
                value="browserDefault"
                control={<Radio />}
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Browser Default</span>
                    <Chip
                      label="Free"
                      size="small"
                      style={{ marginLeft: '8px' }}
                      color="primary"
                      onClick={() => {
                        window.open('https://docs.roboedit.app/', '_blank');
                      }}
                    />
                  </div>

                }
              />
              <span className="settings-caption">
                RoboEdit will use the browser default TTS. Voices are limited to what you have installed on your system (browser-specific).
              </span>
              <br></br>
              {ocrSettings.ttsEngine === 'browserDefault' &&
                <Fragment><select
                  onChange={(e) => setSelectedVoice(voices.find(voice => voice.name === e.target.value))}
                  style={{ maxWidth: '500px', width: '100%' }}
                  value={selectedVoice ? selectedVoice.name : ""}
                >
                  {/* If selectedVoice is set, show it as the first option */}
                  {selectedVoice && (
                    <option value={selectedVoice.name}>
                      {selectedVoice.name} ({selectedVoice.lang})
                    </option>
                  )}

                  {voices.map((voice, index) => (
                    <option key={index} value={voice.name}>
                      {voice.name} ({voice.lang})
                    </option>
                  ))}
                </select>

                  {selectedVoice.name.includes('Google') &&
                    <Fragment>
                      <br></br>
                      <span className="settings-caption">
                        <b>Note:</b> Text highlighting is NOT currently supported for Google neural voices. It is suported for Microsoft Edge (Azure) voices and premium (ElevenLabs) voices
                      </span>
                    </Fragment>
                  }
                  <br></br>
                </Fragment>
              }


              <FormControlLabel
                value="elevenlabs"
                control={<Radio />}
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>ElevenLabs</span>
                    <Chip
                      label="Paid"
                      size="small"
                      style={{ marginLeft: '8px' }}
                      color="warning"
                      onClick={() => {
                        window.open('https://docs.roboedit.app/', '_blank');
                      }}
                    />
                  </div>
                }
              />
              <span className="settings-caption">
                Uses more tokens, but allows access to higher quality voices and reading.
              </span>
              <span className="settings-caption">
                <a href='https://docs.roboedit.app/' target='_blank'>Pricing information available here.</a>
              </span>
              {ocrSettings.ttsEngine === 'elevenlabs' && <Fragment><br></br>
                <select
                  onChange={handleVoiceChange}
                  value={ocrSettings.premiumVoice ? ocrSettings.premiumVoice : '6AKHxakv0gUvEwsGQuHh'}>
                  <option value="6AKHxakv0gUvEwsGQuHh">Shakespearean Actor</option>
                  <option value="wN2qojhJttoNAXo6J8Me">Foreboding</option>
                  <option value="rBeaKz3jjrL853hNWC6C">God</option>
                  <option value="tOrByLpuyV6KSeMXFI42">Greg</option>
                  <option value="qrscIccL0awIocD77ysc">Florence</option>
                  <option value="NuCeJhmD7cPEf6uRDJDN">Victoria</option>
                  <option value="jlxRlLAHFHYYF59Lyibs">Pensive</option>
                  <option value="nbk2esDn4RRk4cVDdoiE">ASMR</option>
                  <option value="LruHrtVF6PSyGItzMNHS">Calm</option>
                  <option value="uVKHymY7OYMd6OailpG5">Barbarian</option>
                  <option value="JoYo65swyP8hH6fVMeTO">Kindly Wizard</option>
                </select>
              </Fragment>}
            </RadioGroup>

            <br></br>

          </Fragment>
          }

          <FormControlLabel
            control={
              <Checkbox
                checked={ocrSettings.readMode}
                onChange={(e) => handleOcrSettingChange('readMode', e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Read Mode"
          />
          <br />
          <span className="settings-caption">
            RoboEdit will attempt to read already existing audio. It will skip the scanning step.
          </span><br></br><br></br>
          <hr></hr>
          <p className="settings-header">
            <b>Enable Sound Effects</b>
          </p>

          <FormControlLabel
            control={
              <Checkbox
                checked={ocrSettings.soundEffectsEnabled}
                onChange={(e) => handleOcrSettingChange('soundEffectsEnabled', e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Sound Effects"
          />
          <br />
          <span className="settings-caption">
            Enable sound effects. Great for live streams, multitasking, or just for fun.
          </span>

          <br></br>
          <hr></hr>
          <p className="settings-header">
            <b>Content Settings</b>
          </p>

          <FormControlLabel
            control={
              <Checkbox
                checked={ocrSettings.textSanitizer}
                onChange={(e) => handleOcrSettingChange('textSanitizer', e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Text Sanitizer"
          />
          <br />
          <span className="settings-caption">
            When enabled, RoboEdit will filter out sensitive words like pejoratives and racial slurs, showing only the first letter of such words.
          </span>

          <br></br>
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={handleSettingsClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: 'var(--accordion-background-color)'
          }
        }}
        open={openDiffViewer} fullWidth={true} maxWidth='xl' onClose={handleCloseDiffViewer}>
        <DialogTitle style={{ color: 'var(--accordion-text-color)' }}>{'Diff Viewer: OCR Scan ↔ AI Proofread'}</DialogTitle>
        <DialogContent id="prompt-editor">
          <DialogContentText style={{ color: 'var(--text-color)' }}>Raw OCR scans often include line breaks, unwanted hyphenations, or just incorrectly rendered characters.</DialogContentText>
          <DialogContentText style={{ color: 'var(--text-color)' }}>RoboEdit proofreads OCR-scanned text to remove this often burdensome task.</DialogContentText>

          <br></br>
          <div>
            <ReactDiffViewer
              useDarkTheme={prefersDarkMode}
              hideLineNumbers
              oldValue={splitParas(rawOcrText)}
              compareMethod={DiffMethod.WORDS}
              newValue={googleOcrText}
              splitView={true}
              showDiffOnly={false} // Add this prop
              styles={{
                diffContainer: {
                  wordBreak: 'break-word',
                },
                contentText: {
                  lineHeight: '17.5px!important'
                },
                wordDiff: {
                  padding: '0px',
                  display: 'inline'
                }
              }}
            />
          </div>
          <br></br>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="warning" onClick={() => handleCloseDiffViewer()}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog onClose={handleScanDialogClose} open={scanDialogOpen}>
        <DialogTitle>Perform OCR Scan</DialogTitle>
        <List sx={{ pt: 0 }}>
          <ListItem disableGutters>
            <ListItemButton
              autoFocus
              onClick={() => handleSingleScan()}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: deepPurple[500] }}>
                  <ScanIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Scan Single Page" secondary="The current page will be scanned. AI will proofread." />
            </ListItemButton>
          </ListItem>
        </List>
        <List sx={{ pt: 0 }}>
          <ListItem disableGutters>
            <ListItemButton
              autoFocus
              onClick={handleStartAutoScan}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: deepOrange[500] }}>
                  <AutoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Auto Scan" secondary="Scan from the current to final page. AI will proofread." />
            </ListItemButton>
          </ListItem>
        </List>
        <br></br>
        <Alert icon={<WarningIcon fontSize="inherit" />} severity="warning">
          Auto Scan will overwrite any existing content. Use with caution.
        </Alert>
      </Dialog>
      {
        showStopAutoScan && !isPlaying && <Tooltip title="Stop auto-scan">
          <Fab
            id='stop-auto-scan'
            color="warning"
            className='FabBtnStopAutoScan'
            onClick={handleStopAutoScan}
          ><CloseIcon /></Fab></Tooltip>
      }
      {
        !isPlaying &&
        <FadeInOut show={show}>
          <Tooltip title="View before/after AI edits">
            <Fab
              disabled={!rawOcrText || isLoading}
              color="secondary"
              className='FabBtnBoxDiff'
              onClick={() => handleOpenDiffViewer()}
            ><DifferenceIcon /></Fab></Tooltip>
        </FadeInOut>
      }

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isUploading}>
        <div style={{ display: 'block' }}>
          <CircularProgress color="inherit" />
        </div>
        <div>
          <Typography variant="body1" color="inherit" align="center">
            &nbsp;&nbsp;&nbsp;&nbsp;Uploading PDF...
          </Typography>
        </div>
      </Backdrop>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isDeleting}>
        <div style={{ display: 'block' }}>
          <CircularProgress color="inherit" />
        </div>
        <div>
          <Typography variant="body1" color="inherit" align="center">
            &nbsp;&nbsp;&nbsp;&nbsp;Deleting PDF...
          </Typography>
        </div>
      </Backdrop>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={false}>
        <div style={{ display: 'block' }}>
          <CircularProgress color="inherit" />
        </div>
        <div>
          <Typography variant="body1" color="inherit" align="center">
            &nbsp;&nbsp;&nbsp;&nbsp;Loading PDF...
          </Typography>
        </div>
      </Backdrop>

    </div >
  )
}

export default OCRScanner;
